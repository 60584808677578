// eslint-disable-next-line object-curly-newline
import {getCurrentInstance, reactive, toRefs, watch} from '@vue/composition-api'
import router from "@/router";
import isEqual from "lodash/isEqual";
import {pickBy} from "lodash/object";
import store from "@/store";
import L from "leaflet";

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()

  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const dateInPast = (firstDate, secondDate) => {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true
  }

  return false
}

export const getVuetify = () => {
  const ins = getCurrentInstance()?.proxy

  return ins && ins.$vuetify ? ins.$vuetify : null
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy

  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

export const isEmpty = value => {
  if (value === null || value === undefined || value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  return false
}

// ——— Get Initial Text from name ——————— //

export const getInitialName = fullName =>
  // eslint-disable-next-line implicit-arrow-linebreak
  fullName
    .split(' ')
    .map(n => n[0])
    .join('')

// ——— Add Alpha To color ——————— //

export const addAlpha = (color, opacity) => {
  const opacityLocal = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)

  return color + opacityLocal.toString(16).toUpperCase()
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToBottom = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = scrollEl.scrollHeight
}

// ——— Perfect Scrollbar Scroll to bottom ——————— //

export const psScrollToTop = psRef => () => {
  const scrollEl = psRef.value.$el || psRef.value
  scrollEl.scrollTop = 0
}

// ————————————————————————————————————
//* ——— Color Manipulations
// ————————————————————————————————————

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const rgbToHex = (r, g, b) => {
  const componentToHex = c => {
    const hex = c.toString(16)

    return hex.length === 1 ? `0${hex}` : hex
  }

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

// Thanks: https://stackoverflow.com/a/5624139/10796681
export const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  // eslint-disable-next-line no-param-reassign
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  /* eslint-disable indent */
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
  /* eslint-enable */
}

export const isAppLaunched = () => {
  const today = new Date();
  const launchDate = process.env.VUE_APP_LAUNCH_DATE
  const [dateValues, timeValues] = launchDate.split(' ');

  const [year, month, day] = dateValues.split('-');
  const [hours, minutes, seconds] = timeValues.split(':');

  const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);

  return today > date;
}

export const stripTags = (message) => {
  let regex = /(<([^>]+)>)/ig;
  return message.replace(/(\r\n|\n|\r)/gm, " ").replace(/\&nbsp;/g, ' ').replace(regex, " ");
}

export const stringLength = (message) => {
    return message.replace(/(<([^>]+)>)/gi, "").length;
}

export const getPictureStatusIcon = (status, isFeatured = false) => {
    if(isFeatured) {
        return 'profilePictureTickedFilledColored';
    }
    if(status === 'a_face') {
        return 'profilePictureFilled';
    }
    if(status === 'a_shape') {
        return 'shapeFilled';
    }
}

export const getPictureStatusIconMargins = (isFeatured = false) => {
    if(isFeatured) {
        return '-mt-1 -mr-1';
    }
}
export const getPictureStatusIconSize = (status, isFeatured = false) => {
    if (status === 'a_face') {
        return isFeatured?'40':'32';
    }
    if(status === 'a_shape') {
        return '96';
    }
}

export const getPictureFilter = (status, defaultColor) => {
  let result = '';

  if (defaultColor===true) {
    result = 'badgeSuccess';
  }

  if(status === 'new'){
    result = 'badgeInfo';
  }
  if(status.startsWith('r_')) {
    result = 'badgeError';
  }

  if(status === 'a_hot' || status === 'new' || status.startsWith('r_')){
    return result + ' ' + 'blur-sm';
  }
  return result;
}

export const areObjectsEqual = (obj1, obj2) => {
    return isEqual(obj1, obj2)
}

export const differenceObjects = (obj1, obj2) => {
    return pickBy(obj2, (v, k) => !isEqual(obj1[k], v));
}

export const dynamicSort = (property) => {
  let sortOrder = 1;
  if(property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export const excerpt = (text,limit) => {
  return text.length > limit ? (
    stripTags(text).substring(0,limit-3).endsWith(' ')
    ||stripTags(text).substring(0,limit-3).endsWith(':')
    ||stripTags(text).substring(0,limit-3).endsWith(';')
    ||stripTags(text).substring(0,limit-3).endsWith(',')
    ||stripTags(text).substring(0,limit-3).endsWith('.')?
      excerpt(text,limit-1)
      :stripTags(text).substring(0,limit-3) + '...')
    : stripTags(text);
}

export const removeBrTags = (str) => {
    const after = /(<div>)(\s*<br>\s*)+/gi;
    str = str.replace(after, '$1');
    const before = /(\s*<br>\s*)+(<\/div>)/gi;
    str = str.replace(before, '$2');
    str = str.replace(/(<br>){2,}/g, '<br><br>');
    return str;
}

export const goToSpecificRoute = (postCategoriesData, slug, obj, query) => {
    let postCategory = postCategoriesData.find(el => el.slug === slug);
    router.push({name: obj.name, params: {postCategory: postCategory.slug, slug: postCategory.onlyPostSlug}, query: query})
}

export const isEmptyChatMessage = (val) => {
    return isEmpty(stripTags(val).replace(/ /g, ''))
}

function roundTo4(val = 0) {
  return val-val%4
}

export const getWidth = (context, containerWidth, offsetExtension) => {
  let screenWidth = screen.width
  let offset = 0;
  let result = 0;


  if (context === 'Map') {
    if (screenWidth < 1025) {
      offset = 48
      result = screenWidth-offset
    } else {
      result = 660
    }
    return 'w-'+result
  }

  if (context === 'ThreadCard') {
    // offset=348
    if (screenWidth < 1025) {
      // Mobile
      offset = 120
      result = roundTo4(screenWidth-offset)
    } else {
      // Desktop
      offset = 176
      result = roundTo4(containerWidth-offset)
    }
    return 'w-'+result
  }

  if (context === 'ThreadCardName') {
    if (screenWidth < 1025) {
      offset = 164
      result = roundTo4(screenWidth-offset)
    } else {
      offset = 196
      result = roundTo4(containerWidth-offset)
    }
    return 'w-'+result
  }

  if (context === 'ThreadCardMessage') {
    if (screenWidth < 1025) {
      offset = 100
      result = roundTo4(screenWidth-offset)
    } else {
      offset = 136
      result = roundTo4(containerWidth-offset)
    }
    return 'w-'+result
  }

  if (context==='ChatUsername') {
    if (screenWidth < 1025) {
      offset = 180+offsetExtension
      result = roundTo4(screenWidth-offset)
      return 'w-'+result
    } else {
      return 'w-full'
    }
  }

  if (context === 'RememberedUserCard') {
    if (screenWidth < 1025) {
      offset = 124
      result = roundTo4(screenWidth-offset)
    } else {
      offset = 160
      result = roundTo4(containerWidth-offset)
    }
    return 'w-'+result
  }
}

export const getGalleryVariant = () => {
  let screenWidth = screen.width
  if (screenWidth < 156*2+30+24) {//modal-picture x 2 + paddingi w modalu + marginesy modala
    return 'gallery1'
  } else if (screenWidth < 156*3+36+24) {//modal-picture x 3 + paddingi w modalu + marginesy modala
    return 'gallery2'
  } else {
    return 'gallery3'
  }
}

export const getHeightOfScreenWidth = (fraction= 1, offset = 0, variant='') => {
  let result = Math.round(fraction * (screen.width-offset))
  if (variant=='gallery-picture') {
    result = Math.round((result-12)/3)-2;
  }
  return 'h-'+result
}

export const getMobileGalleryWidth = () => {
  let result = 0
  let c1 = 187 //12+163+12
  let c2 = 356 //12+163+6+163+12
  let c3 = 525 //12+163+6+163+6+163+12
  if(screen.width < c2) {
    result = c1
  } else if (c2 <= screen.width < c3) {
    result = c2
  } else if (c3 <= screen.width) {
    result = c3
  }
  return 'w-'+result+'px'
}

export const getPictureSquareSize = (isMobile= false, context='') => {
  let result = 0
  let offset = 0
  if ('context' === 'profileOwnerFeatured') {
    if (isMobile) {
      offset = 24
      result = roundTo4(screen.width-offset)
    } else {
      offset = 24
      result = roundTo4(screen.width)
    }
  }
  return 'w-'+result+' h-'+result
}

export const isNarrowMobileDevice = () => {
  if(screen.width < 375) {
    return true
  }
  return false;
}

export const isChatOpenOnMobile = (routeName = 'chats') => {
  if(screen.width < 1025) {
    return routeName === 'chats' && store.getters["chat/currentThread"] !== null;
  } else {
    return false;
  }
}

export const  calculateMarkerStyle = (map, marker, colorBorder, colorFill) => {
  let isMobile = false
  if (screen.width < 1025) {
    isMobile = true
  }
  const zoom = map.getZoom();
  let widthBorder = 1;
  let fillOpacity = 0.5;

  // Kolor wypelnienia - jesli pusty, ustaw na kolor obramowania
  if (!colorFill) {
    colorFill = colorBorder;
  }

  // Bardzo cienkie obramowanie, jesli zoom < 7
  if (zoom < 7) {
    widthBorder = 0.5
  }

  let newRadius = zoom - 4;
  // Lokalizacja uzytkownika
  if (colorBorder === '#1e90ff') {
    newRadius = zoom + 6;
    widthBorder = 2;
  }
  // Wybrana lokalizacja
  else if (colorBorder === '#800000') {
    newRadius = zoom + 2;
    widthBorder = 2;
  }

  // Jesli obramowanie ma inny kolor niz wnetrze - mniejsza przezroczystosc wypelnienia
  if (colorFill && colorBorder !== colorFill) {
    widthBorder = 4;
    fillOpacity = 0.7;
  }

  if (isMobile && zoom>7 && !(colorBorder === '#1e90ff' || colorBorder === '#800000')) {
    newRadius = newRadius+2;
    if(zoom>9) {
      newRadius = newRadius+2;
    }
  }

  marker.setStyle({
    radius: newRadius,
    weight: widthBorder,
    color: colorBorder,
    fillColor: colorFill,
    fillOpacity: fillOpacity
  });
}

export const fitMapToShowLocations = (map, location1, location2) => {
  if (location1 && location2) {
    let distance = location1.getLatLng().distanceTo(location2.getLatLng());
    if (distance > 50000) {
      let bounds = L.latLngBounds([location1.getLatLng(), location2.getLatLng()]);
      // Dostosowanie widoku mapy, aby obejmowała oba markery
      map.fitBounds(bounds, {
        padding: [48, 48]
      });
    }
  }
}

