<template>
  <svg width="25" height="24" viewBox="0 0 25 24"  >
    <path d="M12.6051 12C15.9188 12 18.6051 9.31371 18.6051 6C18.6051 2.68629 15.9188 0 12.6051 0C9.29136 0 6.60507 2.68629 6.60507 6C6.60507 9.31371 9.29136 12 12.6051 12ZM16.6051 6C16.6051 8.20914 14.8142 10 12.6051 10C10.3959 10 8.60507 8.20914 8.60507 6C8.60507 3.79086 10.3959 2 12.6051 2C14.8142 2 16.6051 3.79086 16.6051 6Z" fill="white"/>
    <path d="M24.6051 22C24.6051 24 22.6051 24 22.6051 24H2.60507C2.60507 24 0.605072 24 0.605072 22C0.605072 20 2.60507 14 12.6051 14C22.6051 14 24.6051 20 24.6051 22ZM22.605 21.993C22.6022 21.4995 22.2975 20.0209 20.9408 18.6642C19.6363 17.3597 17.1833 16 12.6051 16C8.02684 16 5.57378 17.3597 4.26927 18.6642C2.91258 20.0209 2.60792 21.4995 2.60507 21.993H22.605Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "ProfilePictureIcon",
}
</script>

