<template>
  <svg width="25" height="25" viewBox="0 0 25 25"  >
    <path d="M12.7461 22.7373C6.9471 22.7373 2.24609 18.0363 2.24609 12.2373C2.24609 6.43832 6.9471 1.7373 12.7461 1.7373C18.5451 1.7373 23.2461 6.43832 23.2461 12.2373C23.2461 18.0363 18.5451 22.7373 12.7461 22.7373ZM12.7461 24.2373C19.3735 24.2373 24.7461 18.8647 24.7461 12.2373C24.7461 5.60989 19.3735 0.237305 12.7461 0.237305C6.11868 0.237305 0.746094 5.60989 0.746094 12.2373C0.746094 18.8647 6.11868 24.2373 12.7461 24.2373Z"/>
    <path d="M7.17328 14.5881C7.53183 14.3807 7.99063 14.5032 8.19803 14.8618C9.10691 16.4329 10.8038 17.4873 12.7461 17.4873C14.6884 17.4873 16.3854 16.4329 17.2942 14.8618C17.5016 14.5032 17.9604 14.3807 18.319 14.5881C18.6775 14.7955 18.8001 15.2543 18.5926 15.6128C17.4267 17.6285 15.2455 18.9873 12.7461 18.9873C10.2467 18.9873 8.0656 17.6285 6.89962 15.6128C6.69222 15.2543 6.81474 14.7955 7.17328 14.5881Z"/>
    <path d="M11.2461 9.9873C11.2461 11.2299 10.5745 12.2373 9.74609 12.2373C8.91767 12.2373 8.24609 11.2299 8.24609 9.9873C8.24609 8.74466 8.91767 7.7373 9.74609 7.7373C10.5745 7.7373 11.2461 8.74466 11.2461 9.9873Z"/>
    <path d="M17.2461 9.9873C17.2461 11.2299 16.5745 12.2373 15.7461 12.2373C14.9177 12.2373 14.2461 11.2299 14.2461 9.9873C14.2461 8.74466 14.9177 7.7373 15.7461 7.7373C16.5745 7.7373 17.2461 8.74466 17.2461 9.9873Z"/>
  </svg>

</template>

<script>
export default {
  name: "SmileIcon",

}
</script>
