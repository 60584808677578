<template>
  <svg width="25" height="24" viewBox="0 0 25 24"  >
    <path d="M2.85181 24C2.85181 24 0.851807 24 0.851807 22C0.851807 20 2.85181 14 12.8518 14C22.8518 14 24.8518 20 24.8518 22C24.8518 24 22.8518 24 22.8518 24H2.85181Z" fill="white"/>
    <path d="M12.8518 12C16.1655 12 18.8518 9.31371 18.8518 6C18.8518 2.68629 16.1655 0 12.8518 0C9.5381 0 6.85181 2.68629 6.85181 6C6.85181 9.31371 9.5381 12 12.8518 12Z" fill="white"/>
  </svg>

</template>

<script>
export default {
  name: "ProfilePictureFilledIcon",
}
</script>
