// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    adminDashboardData: null,
    loadingAdminDashboardData:true,

    descriptionData: false,
    loadingAdminDescriptionsData:true,

    adminPicturesData: null,
    loadingAdminPicturesData:true,

    descriptionUpdating: false,
    pictureUpdating: false,

    adminPostsData: null,
    loadingAdminPostsData: true,

    adminPostShow: null,
    postLoaded: false,

    storePostLoading: false,
    postRemoving: [],
})

// getters
const getters = {
    adminDashboardData: state => state.adminDashboardData,
    adminDashboardDataLoading: state => state.loadingAdminDashboardData,

    // Descriptions
    descriptionData: state => state.descriptionData.description,
    descriptionDataLoading: state => state.loadingAdminDescriptionsData,

    descriptionStatusesNegative: state => state.descriptionData.statusesNegative,
    descriptionStatusesPositive: state => state.descriptionData.statusesPositive,
    descriptionUpdating: state => state.descriptionUpdating,
    descriptionsCounter: state => state.descriptionData.descriptionsCounter,

    // Pictures
    picturesData: state => state.adminPicturesData?.pictures,
    picturesDataLoading: state => state.loadingAdminPicturesData,

    picturesStatusesNegative: state => state.adminPicturesData.statusesNegative,
    picturesStatusesPositive: state => state.adminPicturesData.statusesPositive,
    picturesCounter: state => state.adminPicturesData.picturesCounter,
    pictureUpdating: state => state.pictureUpdating,

    // Posts
    adminPostsData: state => state.adminPostsData,
    adminPostsDataLoading: state => state.loadingAdminPostsData,

    adminPostShow: state => state.adminPostShow,
    postLoaded: state => state.postLoaded,
    storePostLoading: state => state.storePostLoading,
    postRemoving: state => state.postRemoving,

    savedPost: state => state.savedPost,
};

//actions
const actions = {
    fetchAdminDashboardData: ({ commit, dispatch}) => {
        api.setHeader();
        commit('setAdminDashboardDataLoading', true);
        api.get(`/admin`, '', true)
        .then(
            success => {
                commit('setAdminDashboardData', success.data)
            },
            error => {
                dispatch('alert/error', error, { root: true });
            }
        ).finally(() => {
            commit('setAdminDashboardDataLoading', false);
        });
    },

    fetchDescriptionData: ({commit, dispatch}) => {
        api.setHeader();
        commit('setAdminDescriptionDataLoading', true);
        api.get(`/admin/descriptions`, '', true)
            .then(
                success => {
                    commit('setDescriptionData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setAdminDescriptionDataLoading', false);
        });
    },

    updateDescriptionStatus: ({commit, dispatch}, payload) => {
        commit('setDescriptionUpdating', true);
        api.patch(`/admin/descriptions`, payload)
            .then(
                success => {
                    commit('updateDescriptionsCounter');
                    dispatch('fetchDescriptionData')
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setDescriptionUpdating', false);
        });
    },

    fetchAdminPicturesData: ({commit, dispatch}) => {
        api.setHeader();
        commit('setAdminPicturesDataLoading', true);
        api.get(`/admin/pictures`, '', true)
            .then(
                success => {
                    commit('setAdminPicturesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setAdminPicturesDataLoading', false);
        });
    },

    updatePicturesStatus: ({commit, dispatch}, payload) => {
        commit('setPictureUpdating', true);
        api.patch(`/admin/pictures`, payload)
            .then(
                success => {
                    commit('updatePicturesCounter');
                    dispatch('fetchAdminPicturesData')
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setPictureUpdating', false);
        });
    },

    fetchAdminPostsData: ({commit, dispatch}) => {
        api.setHeader();
        commit('setAdminPostsDataLoading', true);

        api.get(`/admin/posts`, '', true)
            .then(
                success => {
                    commit('setAdminPostsData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setAdminPostsDataLoading', false);
            });
    },

    fetchAdminPostShow: ({commit, dispatch}, payload) => {
        api.setHeader();
        commit('setPostLoaded', false);
        api.get(`/admin/posts`, payload, true)
            .then(
                success => {
                    commit('setAdminPostShow', success.data)
                    commit('setPostLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    storeAdminPost: ({commit, dispatch}, payload) => {
      api.setHeader();
      commit('setStorePostLoading', true);
        api.post(`/admin/posts`, payload)
            .then(
                success => {
                    router.push({name: 'admin-texts'});
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setStorePostLoading', false);
        });
    },

    updateAdminPost: ({commit, dispatch}, payload) => {
        api.setHeader();
        commit('setStorePostLoading', true);
        commit('setPostLoaded', false);
        api.post(`/admin/posts/${payload.postId}`, payload.data)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                    commit('setAdminPostShow', success.data.post);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPostLoaded', true);
            commit('setStorePostLoading', false);
        });
    },

    removeAdminPost: ({commit, dispatch}, payload) => {
        api.setHeader();
        commit('setPostRemoving', payload.postId);
        api.delete(`/admin/posts/${payload.postId}`)
            .then(
                success => {
                    commit('removeAdminPostFromArray', payload.postId);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPostRemoving', payload.postId, true);
        });
    },

}

//mutations
const mutations = {
    setAdminDashboardData(state, data) {
        state.adminDashboardData = data;
    },
    setAdminDashboardDataLoading (state, value) {
        state.loadingAdminDashboardData = value;
    },

    setAdminDescriptionsData(state, data) {
        state.descriptionData = data;
    },

    setDescriptionData(state, data) {
        state.descriptionData = data;
    },

    setAdminDescriptionDataLoading (state, value) {
        state.loadingAdminDescriptionsData = value;
    },

    setDescriptionUpdating(state, value) {
        state.descriptionUpdating = value;
    },

    setAdminPicturesData(state, data) {
        state.adminPicturesData = data;
    },
    setAdminPicturesDataLoading (state, value) {
        state.loadingAdminPicturesData = value;
    },

    setPictureUpdating(state, value) {
        state.pictureUpdating = value;
    },

    updatePicturesCounter(state) {
      state.adminPicturesData.picturesCounter--;
    },

    updateDescriptionsCounter(state) {
      state.descriptionData.descriptionsCounter--;
    },

    setAdminPostsData(state, data) {
        state.adminPostsData = data;
    },

    setAdminPostsDataLoading (state, value) {
        state.loadingAdminPostsData = value;
    },

    setAdminPostShow(state, data) {
        state.adminPostShow = data;
    },

    setPostLoaded(state, value) {
        state.postLoaded = value;
    },

    setStorePostLoading(state, value) {
        state.storePostLoading = value;
    },

    setPostRemoving(state, value, remove = false) {
        if(remove) {
            state.postRemoving.splice(value, 1);
        } else {
            state.postRemoving.push(value);
        }
    },

    removeAdminPostFromArray(state, value) {
        state.adminPostsData.splice(state.adminPostsData.findIndex(el => el.id === value), 1);
    },


}

export const admin = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
