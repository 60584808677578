<template>
  <svg width="17" height="16" viewBox="0 0 17 16"  >
    <path d="M8.74609 15.7373C-6.58704 5.60571 4.02485 -2.30356 8.57041 1.88039C8.63004 1.93527 8.68862 1.99224 8.74609 2.05131C8.80356 1.99224 8.86214 1.93528 8.92177 1.8804C13.4673 -2.30357 24.0792 5.60571 8.74609 15.7373Z" fill="#A00000"/>
  </svg>

</template>

<script>
export default {
  name: "HeartFilledIcon",

}
</script>
