import Vue from "vue";
import { extend, localize } from "vee-validate";
import { required, email, regex, min, max, min_value, max_value, confirmed } from "vee-validate/dist/rules";
import store from "@/store/index"

extend('required', required);

extend('email', email);

extend('regex', {...regex});
extend('confirmed', {...confirmed});

extend('min', {
  ...min,
  params: ['length']
})

extend('max', {
  ...max,
  params: ['length']
})

extend('min_value', {
  ...min_value,
  params: ['length']
})

extend('max_value', {
  ...max_value,
  params: ['length']
})

localize({
  en: {
    messages: {
      required: field =>  `This field is required`,
      min: (field, args) =>  `This field must be at least ${args.length} characters`,
      max: (field, args) =>  `This field must not be greater than ${args.length} characters`,
      min_value: (field, args) =>  `This field must be greater than ${args.length}`,
      max_value: (field, args) =>  `This field must not be greater than ${args.length}`,
      email: field =>  "This field must be a valid email address",
      confirmed: field =>  "The password confirmation does not match.",
    }
  },
  pl: {
    messages: {
      required: field =>  `To pole jest wymagane`,
      min: (field, args) =>  `Zawartość pola musi zawierać co najmniej ${args.length} znaków.`,
      max: (field, args) =>  `Zawartość pola nie może zawierać więcej niż ${args.length} znaków.`,
      min_value: (field, args) =>  `Zawartość pola musi być większa od ${args.length}.`,
      max_value: (field, args) =>  `Zawartość pola nie może być większa od ${args.length}.`,
      email: field =>  `Zawartość pola musi być poprawnym adresem e-mail.`,
      confirmed: field =>  `Zawartość pól nie zgadza się.`,
    }
  },
  uk: {
    messages: {
      required: field =>  `Поле є обов'язковим для заповнення`,
      min: (field, args) =>  `Поле повинне бути не менше ${args.length}`,
      max: (field, args) =>  `Текст в полі повинен мати довжину не більшу за ${args.length}`,
      min_value: (field, args) =>  `Вміст поля має бути більше ніж ${args.length}`,
      max_value: (field, args) =>  `Вміст поля не може перевищувати ${args.length}`,
      email: field =>  `Поле повинне містити коректну електронну адресу`,
      confirmed: field =>  `Поле не збігається з підтвердженням`,
    }
  }
});

