<template>
  <svg width="16" height="17" viewBox="0 0 16 17"  >
    <path d="M5.14557 6.60397C5.44013 6.60397 5.67891 6.84275 5.67891 7.13731V13.5373C5.67891 13.8319 5.44012 14.0706 5.14557 14.0706C4.85102 14.0706 4.61224 13.8319 4.61224 13.5373V7.13731C4.61224 6.84275 4.85102 6.60397 5.14557 6.60397Z" />
    <path d="M7.81224 6.60397C8.10679 6.60397 8.34557 6.84275 8.34557 7.13731V13.5373C8.34557 13.8319 8.10679 14.0706 7.81224 14.0706C7.51769 14.0706 7.27891 13.8319 7.27891 13.5373V7.13731C7.27891 6.84275 7.51769 6.60397 7.81224 6.60397Z" />
    <path d="M11.0122 7.13731C11.0122 6.84275 10.7735 6.60397 10.4789 6.60397C10.1844 6.60397 9.94557 6.84275 9.94557 7.13731V13.5373C9.94557 13.8319 10.1844 14.0706 10.4789 14.0706C10.7735 14.0706 11.0122 13.8319 11.0122 13.5373V7.13731Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7456 3.9373C14.7456 4.52641 14.268 5.00397 13.6789 5.00397H13.1456V14.604C13.1456 15.7822 12.1904 16.7373 11.0122 16.7373H4.61224C3.43403 16.7373 2.47891 15.7822 2.47891 14.604V5.00397H1.94557C1.35647 5.00397 0.878906 4.52641 0.878906 3.9373V2.87064C0.878906 2.28153 1.35647 1.80397 1.94557 1.80397H5.67891C5.67891 1.21487 6.15647 0.737305 6.74557 0.737305H8.87891C9.46801 0.737305 9.94557 1.21487 9.94557 1.80397H13.6789C14.268 1.80397 14.7456 2.28153 14.7456 2.87064V3.9373ZM3.67148 5.00397L3.54557 5.06692V14.604C3.54557 15.1931 4.02314 15.6706 4.61224 15.6706H11.0122C11.6013 15.6706 12.0789 15.1931 12.0789 14.604V5.06692L11.953 5.00397H3.67148ZM1.94557 3.9373V2.87064H13.6789V3.9373H1.94557Z" />
  </svg>

</template>

<script>
export default {
  name: "TrashIcon",

}
</script>
