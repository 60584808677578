<template>
    <svg width="1024" height="1024" viewBox="0 0 1024 1024"  >
        <path d="M416 160a96 96 0 1 0 192 0 96 96 0 1 0-192 0Z" fill="white" />
        <path d="M848 288H176c-26.6 0-48 21.4-48 48s21.4 48 48 48h197c11.2 2 26.4 7.6 34.4 28.4 9.4 24.2 4.8 67.2-1 103.4l-7.6 42.8c0 0.2 0 0.4-0.2 0.6l-60.8 344.4c-4.6 26 12.8 51 39 55.6 26.2 4.6 50.6-12.8 55.2-39l42-239.8v0.4s12.4-65 37-65h2.2c25 0 37 65 37 65v-0.2l42 239.8c4.6 26 29.4 43.4 55.4 38.8 26.2-4.6 43.4-29.6 38.8-55.6l-60.8-344.4c0-0.2 0-0.4-0.2-0.6l-7.6-42.8c-5.8-36.2-10.4-79.2-1-103.4 8-20.8 23.2-26.4 34.4-28.4H848c26.6 0 48-21.4 48-48s-21.4-48-48-48z" fill="white" />
    </svg>
</template>

<script>
export default {
  name: "ShapeFilledIcon",
}
</script>

