<script>
import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';

Vue.mixin({
  computed: {
    ...mapState('lang', {
      langData: state => state.langData
    }),
    ...mapGetters('lang', ['langLocale'])
  },
  methods: {
    trans(key, gender=null) {
      if(this.langData && this.langData[key]) {
        if(gender && this.langData[key].includes('|')) {
          if(gender === 'both') {
            let key_m = this.trans(key,'m')
            let key_w = this.trans(key,'w')
            if (key_m === key_w) {
              return key_m
            }
            else {
              return key_m+' / '+key_w
            }
          }
        }
        return this.langData[key] === undefined ? key : this.langData[key];
      }
      return key;
    },

    transChoice(key, attr) {
      if(this.langData) {
        let str = this.langData[key];
        return str.replace(str.match(/{([^}]+)}/)[1], attr).replace(/[{}]/g, '');
      }
      return key;
    },
  },
})

export default {
  name: "getTranslation"
}
</script>
