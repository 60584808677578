const fromQueryToObject = function(entries) {
    const urlParams = new URLSearchParams(entries)
    entries = urlParams.entries();

    const result = {}
    for(const [key, value] of entries) { // each 'entry' is a [key, value] tuple
        if(isNaN(parseInt(value))) {
            result[key] = value;
        } else {
            result[key] = parseInt(value);
        }
    }
    return result;
}

export default fromQueryToObject;