<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path fill="currentColor" d="M14.5016 1.93934C14.6969 2.1346 14.6969 2.45118 14.5016 2.64645L13.4587 3.68933L11.4587 1.68933L12.5016 0.646447C12.6969 0.451184 13.0134 0.451185 13.2087 0.646447L14.5016 1.93934Z"/>
    <path fill="currentColor" d="M12.7516 4.39644L10.7516 2.39644L3.93861 9.20943C3.88372 9.26432 3.84237 9.33123 3.81782 9.40487L3.01326 11.8186C2.94812 12.014 3.13405 12.1999 3.32949 12.1348L5.74317 11.3302C5.81681 11.3057 5.88372 11.2643 5.93861 11.2094L12.7516 4.39644Z"/>
    <path fill="currentColor" d="M0 13.5C0 14.3284 0.671573 15 1.5 15H12.5C13.3284 15 14 14.3284 14 13.5V7.5C14 7.22386 13.7761 7 13.5 7C13.2239 7 13 7.22386 13 7.5V13.5C13 13.7761 12.7761 14 12.5 14H1.5C1.22386 14 1 13.7761 1 13.5V2.5C1 2.22386 1.22386 2 1.5 2H8C8.27614 2 8.5 1.77614 8.5 1.5C8.5 1.22386 8.27614 1 8 1H1.5C0.671573 1 0 1.67157 0 2.5V13.5Z"/>
  </svg>


</template>

<script>
export default {
  name: "EditPencilIcon",
}
</script>
