import api from "@/common/services/api";
import {dynamicSort} from "@/common/helpers/utils";

const state = () => ({
    currentSearchNav: 'search',
    historyShown: true,
    picturesLoadingCounter: 0,

})

// getters
const getters = {
    currentSearchNav: state => state.currentSearchNav,
    historyShown: state => state.historyShown,
    picturesLoadingCounter: state => state.picturesLoadingCounter,
};

//actions
const actions = {

}

//mutations
const mutations = {
    setCurrentNav (state, value) {
        state.currentSearchNav = value;
    },
    setHistoryShown (state, value) {
        state.historyShown = value;
    },
    setPicturesLoadingCounter(state, value) {
        state.picturesLoadingCounter = value;
    },

}

export const utils = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
