// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    partnersData: [],
    loading: false,
    loaded: false,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
})

// getters
const getters = {
    partnersData: state => state.partnersData,
    partnersLoading: state => state.loading,
    partnersLoaded: state => state.loaded,
    pagination: state => state.pagination,
};

//actions
const actions = {
    fetchPartnersData: ({ commit, getters, state, dispatch}, payload = null) => {

        commit('setLoading', true);
        commit('setLoaded', false);

        api.setHeader();
        api.query(`/partners`)
            .then(
                success => {
                    commit('setPartnersData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoading', false);
                commit('setLoaded', true);
        });
    },

    setPage({commit}, value) {
        commit('setCurrentPage', value);
    },

}

//mutations
const mutations = {
    setPartnersData (state, data) {
        state.partnersData = data;
    },
    setPagination (state, data) {
        if(data) {
            state.pagination = {
                currentPage: data.current_page,
                from: data.from,
                lastPage: data.last_page,
                perPage: data.per_page,
                to: data.to,
                total: data.total,
            }
        }
    },
    setCurrentPage (state, value) {
        if(state.pagination) {
            state.pagination.currentPage = value;
        }
    },
    setLoading (state, value) {
        state.loading = value;
    },
    setLoaded (state, value) {
        state.loaded = value;
    },
    setLastPage(state, page) {
        state.lastPage = page;
    },

}

export const partners = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
