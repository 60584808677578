<template>
  <svg width="48" height="48" viewBox="0 0 48 48"  >
    <path d="M35.2265 31.0317C37.5987 27.8019 39 23.8146 39 19.5C39 8.73045 30.2696 0 19.5 0C8.73045 0 0 8.73045 0 19.5C0 30.2696 8.73045 39 19.5 39C23.8158 39 27.804 37.598 31.0343 35.2246L31.0317 35.2265C31.1201 35.3465 31.2187 35.4613 31.3273 35.5699L42.8787 47.1213C44.0503 48.2929 45.9498 48.2929 47.1213 47.1213C48.2929 45.9498 48.2929 44.0503 47.1213 42.8787L35.5699 31.3273C35.4613 31.2187 35.3465 31.1201 35.2265 31.0317ZM36 19.5C36 28.6127 28.6127 36 19.5 36C10.3873 36 3 28.6127 3 19.5C3 10.3873 10.3873 3 19.5 3C28.6127 3 36 10.3873 36 19.5Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: "MagnifierIcon",
}
</script>
