// initial state
import api from "@/common/services/api";


const state = () => ({
    langData: null,
    updatedData: null,
    langLocale: null,
    locales: ['pl', 'en', 'uk'],
    langLoaded: false,
    configData: null,
})

// getters
const getters = {
    langData: state => state.langData,
    configData: state => state.configData,
    validationData: state => state.validationData,
    langLocale: state => !state.langLocale ? !localStorage.getItem('langLocale') ? 'pl' : localStorage.getItem('langLocale') : state.langLocale,
    locales: state => state.locales,
    langLoaded: state => state.langLoaded,
};

//actions
const actions = {
    fetchLangData: ({ commit, getters, dispatch}, langLocale) => {
        let lng = langLocale === undefined ? getters.langLocale : langLocale

        commit('setLocale', lng)
        commit('setLangLoaded', false);
        api.post('/get-lang', {locale: lng})
            .then(
                success => {
                    commit('setLangData', success.data.lang)
                    commit('setConfigData', success.data.config)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLangLoaded', true);
        });
    },

    updateLang: ({ commit, getters, dispatch }, langLocale) => {
        commit('setLocale', langLocale)
    }
}

//mutations
const mutations = {
    setLangData (state, lang) {
        state.langData = lang
    },
    setConfigData (state, lang) {
        state.configData = lang
    },
    setLocale (state, langLocale) {
        state.langLocale = langLocale;
        localStorage.setItem('langLocale', langLocale);
    },
    setLangLoaded(state, value) {
        state.langLoaded = value;
    }
}

export const lang = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
