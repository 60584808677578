<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
  </svg>

</template>

<script>
export default {
  name: "DotsVerticalIcon",

}
</script>
