import { ADD_TOAST_MESSAGE } from 'vuex-toast'

const state = {
    type: null,
    message: null
};

const actions = {
    success({ dispatch, commit }, success) {
        if(success.message !== undefined) {
            dispatch(ADD_TOAST_MESSAGE, { text: success.message, type: 'success' }, { root: true })
            commit('success', success);
        }
    },
    error({ dispatch, commit }, error) {
        if(error.hasOwnProperty('data') && error.data.length > 0) {
            dispatch(ADD_TOAST_MESSAGE, { text: error.message, type: 'danger' }, { root: true })
        }
        if(error.errors !== undefined) {
            for (let key in error.errors) {
                if (error.errors.hasOwnProperty(key)) {
                    dispatch(ADD_TOAST_MESSAGE, { text: error.errors[key][0], type: 'danger' }, { root: true })
                }
            }
        } else {
            dispatch(ADD_TOAST_MESSAGE, { text: error.message, type: 'danger' }, { root: true })
        }
        commit('error', error);
    },
    clear({ commit }) {
        commit('clear');
    }
};

const mutations = {
    success(state, message) {
        state.type = 'alert-success';
        state.message = message;
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;

    },
    clear(state) {
        state.type = null;
        state.message = null;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
