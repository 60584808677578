<template>
  <svg width="16" height="17" viewBox="0 0 16 17"  >
    <path d="M8 14.2373C11.3137 14.2373 14 11.551 14 8.2373C14 4.9236 11.3137 2.2373 8 2.2373C4.68629 2.2373 2 4.9236 2 8.2373C2 11.551 4.68629 14.2373 8 14.2373Z" stroke="#DC143C"/>
    <path d="M7.73328 5.03735H8.26661L7.99994 8.90402L7.73328 5.03735Z" stroke="#DC143C" stroke-linejoin="round"/>
    <path d="M7.99995 11.9706C8.44178 11.9706 8.79995 11.6124 8.79995 11.1706C8.79995 10.7288 8.44178 10.3706 7.99995 10.3706C7.55812 10.3706 7.19995 10.7288 7.19995 11.1706C7.19995 11.6124 7.55812 11.9706 7.99995 11.9706Z" fill="#DC143C"/>
  </svg>
</template>

<script>
export default {
  name: "ErrorIcon"
}
</script>
