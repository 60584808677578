import api from "@/common/services/api";
import {dynamicSort} from "@/common/helpers/utils";
import router from "@/router";
import {REPORTS} from "@/common/helpers/thread-types";

const state = () => ({
    adminMessageSending: false,
    adminMessageSent: false,


})

// getters
const getters = {
    adminMessageSending: state => state.adminMessageSending,
    adminMessageSent: state => state.adminMessageSent,

};

//actions
const actions = {
    sendAdminMessageRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setAdminMessageSending', true);
        commit('setAdminMessageSent', false);

        api.post(`/admin-message`, payload)
            .then(
                success => {
                    commit('setAdminMessageSent', true)
                    if(payload.reportedUsername || success.data.threadId) {
                        if(router.currentRoute.name !== 'chats') {
                            commit('chat/setAdminMessageThreadId', success.data.threadId, { root: true })
                            router.push({name: 'chats'})
                        } else {
                            commit('chat/setAdminMessageThreadId', success.data.threadId, { root: true })
                            if(Number(router.currentRoute.params.id) !== Number(success.data.threadId)) {
                                commit('chat/setCurrentThread', null, { root: true })
                            }
                        }
                    }
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setAdminMessageSending', false)

        });
    },


}

//mutations
const mutations = {
    setAdminMessageSending (state, value) {
        state.adminMessageSending = value;
    },

    setAdminMessageSent (state, value) {
        state.adminMessageSent = value;
    },


}

export const contact = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
