import api from "@/common/services/api";
import Vue from "vue";

const state = () => ({
    dashboardData: null,
    dashboardLoaded: false,
})

// getters
const getters = {
    dashboardData: state => state.dashboardData,
    dashboardLoaded: state => state.dashboardLoaded,
};

//actions
const actions = {
    fetchDashboardData ({ commit, dispatch}) {
        commit('setDashboardLoaded', false);

        api.setHeader();
        api.get(`/dashboard`)
            .then(
                success => {
                    commit('setDashboardData', success.data)
                    commit('setDashboardLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

}

//mutations
const mutations = {
    setDashboardData (state, data) {
        state.dashboardData = data;
    },

    updateDashboardMessagesData(state, data) {
        Vue.set(state.dashboardData.messages, data.attribute, data.value)
    },

    setDashboardLoaded(state, value) {
        state.dashboardLoaded = value;
    },

    clearDashboardData(state) {
        state.dashboardData = null;
    }

}

export const dashboard = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
