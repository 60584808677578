import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';
import { Validator } from 'vee-validate';

extend('required', {
    ...required,
});
extend('email', {
    ...email,
});

