// initial state
import api from "@/common/services/api";


const state = () => ({
    searchHistoryData: null,
})

// getters
const getters = {
    searchHistoryData: state => state.searchHistoryData,
};

//actions
const actions = {
    fetchSearchHistory: ({ commit, getters, dispatch}) => {
        api.get('/search-history', '', false)
            .then(
                success => {
                    commit('setSearchHistory', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },
}

//mutations
const mutations = {
    setSearchHistory (state, data) {
        state.searchHistoryData = data
    },
}

export const searchHistories = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
