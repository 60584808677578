<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M2.67789 11.894C2.88828 12.1049 2.99363 12.3988 2.96523 12.6954C2.90159 13.3601 2.75502 14.0519 2.56677 14.6945C3.96166 14.3721 4.81358 13.9981 5.20082 13.8019C5.42062 13.6905 5.67381 13.6642 5.91182 13.728C6.56966 13.9044 7.27076 14 8 14C11.9956 14 15 11.1925 15 8C15 4.80754 11.9956 2 8 2C4.0044 2 1 4.80754 1 8C1 9.46809 1.6173 10.8304 2.67789 11.894ZM2.18489 15.7989C2.17816 15.8003 2.17142 15.8016 2.16466 15.8029C2.07283 15.821 1.97922 15.8388 1.88382 15.8563C1.75 15.8808 1.61264 15.9047 1.47172 15.9277C1.27271 15.9603 1.12037 15.7521 1.19898 15.5664C1.25034 15.4451 1.30097 15.3179 1.3503 15.1861C1.38164 15.1023 1.41245 15.0167 1.44259 14.9294C1.44389 14.9256 1.44518 14.9219 1.44648 14.9181C1.69422 14.1984 1.89596 13.3711 1.96979 12.6001C0.743061 11.3699 0 9.76087 0 8C0 4.13401 3.58172 1 8 1C12.4183 1 16 4.13401 16 8C16 11.866 12.4183 15 8 15C7.18324 15 6.39508 14.8929 5.65284 14.6939C5.13337 14.9571 4.01434 15.4363 2.18489 15.7989Z"/>
  </svg>
</template>

<script>
export default {
  name: "ChatIcon"
}
</script>
