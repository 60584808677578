<template>
  <svg  width="16" height="16" viewBox="0 0 16 16">
    <path fill="#088800" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM4.756 4.566c.763-1.424 4.02-.12.952 3.434-4.496-1.596-2.35-4.298-.952-3.434zm6.559 5.448a.5.5 0 0 1 .548.736A4.498 4.498 0 0 1 7.965 13a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .548-.736h.005l.017.005.067.015.252.055c.215.046.515.108.857.169.693.124 1.522.242 2.152.242.63 0 1.46-.118 2.152-.242a26.58 26.58 0 0 0 1.109-.224l.067-.015.017-.004.005-.002zm-.07-5.448c1.397-.864 3.543 1.838-.953 3.434-3.067-3.554.19-4.858.952-3.434z"/>
<!--  <svg width="17" height="17" viewBox="0 0 17 17"  >-->
<!--    <path d="M8.6745 15.2373C4.80851 15.2373 1.6745 12.1033 1.6745 8.2373C1.6745 4.37131 4.80851 1.2373 8.6745 1.2373C12.5405 1.2373 15.6745 4.37131 15.6745 8.2373C15.6745 12.1033 12.5405 15.2373 8.6745 15.2373ZM8.6745 16.2373C13.0928 16.2373 16.6745 12.6556 16.6745 8.2373C16.6745 3.81903 13.0928 0.237305 8.6745 0.237305C4.25622 0.237305 0.6745 3.81903 0.6745 8.2373C0.6745 12.6556 4.25622 16.2373 8.6745 16.2373Z" fill="#088800"/>-->
<!--    <path d="M11.9891 10.2508C12.1833 10.2048 12.3863 10.2788 12.5055 10.439C12.6246 10.5992 12.637 10.8149 12.537 10.9877C11.7597 12.3314 10.3056 13.2373 8.63937 13.2373C6.97311 13.2373 5.51901 12.3314 4.7417 10.9877C4.64173 10.8149 4.65415 10.5992 4.77328 10.439C4.89241 10.2788 5.0954 10.2048 5.28966 10.2508L5.29468 10.252L5.31146 10.2559L5.37829 10.2712C5.43694 10.2844 5.52277 10.3035 5.6302 10.3265C5.84524 10.3725 6.1459 10.4339 6.48777 10.4952C7.18008 10.6193 8.00972 10.7373 8.63937 10.7373C9.26902 10.7373 10.0987 10.6193 10.791 10.4952C11.1328 10.4339 11.4335 10.3725 11.6485 10.3265C11.756 10.3035 11.8418 10.2844 11.9005 10.2712L11.9673 10.2559L11.9841 10.252L11.9891 10.2508Z" fill="#088800"/>-->
<!--    <path d="M5.43019 4.80379C6.19321 3.37958 9.44966 4.68319 6.38238 8.2373C1.88682 6.64116 4.03239 3.939 5.43019 4.80379Z" fill="#088800"/>-->
<!--    <path d="M11.9188 4.80379C13.3166 3.939 15.4622 6.64116 10.9666 8.2373C7.89934 4.68319 11.1558 3.37958 11.9188 4.80379Z" fill="#088800"/>-->
  </svg>
</template>

<script>
export default {
  name: "EmojiHeartIcon"
}
</script>
