<template>
  <t-tag class="w-min whitespace-nowrap" :tag-name="tagName" :variant="variant">
    <slot>

    </slot>
  </t-tag>
</template>

<script>
export default {
  name: "Badge",
  props: {
    tagName: {
      type: String,
    },
    variant: {
      type: String,
    },
  }
}
</script>