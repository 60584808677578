const partnerCodes = [
    {
        path: '/admin/partner-codes',
        name: 'admin-partner-codes',
        component: () => import('@/views/content/admin/partner-codes/AdminPartnerCodesList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/partner-codes/new',
        name: 'admin-partner-codes-new',
        component: () => import('@/views/content/admin/partner-codes/AdminPartnerCodeShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/partner-codes/:id',
        name: 'admin-partner-codes-show',
        component: () => import('@/views/content/admin/partner-codes/AdminPartnerCodeShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },

]

export default partnerCodes;
