<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
  </svg>
</template>

<script>
export default {
  name: "LockFilledIcon",

}
</script>
