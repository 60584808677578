import api from "@/common/services/api";

const state = () => ({
    adToShow: null,
    adLoading: false,
})

// getters
const getters = {
    adToShow: state => state.adToShow,
    adLoading: state => state.adLoading,
};

//actions
const actions = {
    fetchAd ({ commit, dispatch}) {
        commit('setAdLoading', true);

        api.setHeader();
        api.get(`/ads`)
            .then(
                success => {
                    commit('setAdToShow', success.data.slug)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setAdLoading', false);
        });
    },

}

//mutations
const mutations = {
    setAdToShow (state, data) {
        state.adToShow = data;
    },

    setAdLoading (state, value) {
        state.adLoading = value;
    }
}

export const ads = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
