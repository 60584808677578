<script>
import Vue from 'vue';
import {mapGetters, mapState} from 'vuex';

Vue.mixin({
  computed: {
    ...mapState('lang', {
      langData: state => state.langData
    }),
    ...mapGetters('lang', ['langLocale'])
  },
  methods: {
    hasPermission(user, permission) {
      return user.permissions.includes(permission);
    },
  },
})

export default {
  name: "hasPermission"
}
</script>
