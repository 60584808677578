<template>
  <svg width="17" height="17" viewBox="0 0 17 17"  >
    <path d="M1 0L0 1L2.20088 4.08124C2.3886 4.34403 2.69167 4.5 3.01462 4.5H3.08579C3.351 4.5 3.60536 4.60536 3.79289 4.79289L6.46789 7.46789L3.85099 10.1224C3.58121 10.0428 3.2956 10 3 10C1.34315 10 0 11.3431 0 13C0 14.6569 1.34315 16 3 16C4.65685 16 6 14.6569 6 13C6 12.7044 5.95725 12.4188 5.87759 12.149L8.53212 9.53212L9.5 10.5L9.19544 11.4137C9.07566 11.773 9.16918 12.1692 9.43702 12.437L12.7929 15.7929C13.1834 16.1834 13.8166 16.1834 14.2071 15.7929L15.7929 14.2071C16.1834 13.8166 16.1834 13.1834 15.7929 12.7929L12.437 9.43702C12.1692 9.16918 11.773 9.07566 11.4137 9.19544L10.5 9.5L9.53926 8.53926L12.2191 5.89737C12.4681 5.9643 12.7299 6 13 6C14.6569 6 16 4.65685 16 3C16 2.73126 15.9647 2.47078 15.8984 2.22294L13.7574 4.36396L12 3.99998L11.636 2.24264L13.7771 0.101615C13.5292 0.0353359 13.2687 0 13 0C11.3431 0 10 1.34315 10 3C10 3.27012 10.0357 3.53191 10.1026 3.78089L7.46076 6.46075L4.79289 3.79289C4.60536 3.60536 4.5 3.351 4.5 3.08579V3.01462C4.5 2.69167 4.34403 2.3886 4.08124 2.20088L1 0ZM10.6464 10.6464C10.8417 10.4512 11.1583 10.4512 11.3536 10.6464L14.3536 13.6464C14.5488 13.8417 14.5488 14.1583 14.3536 14.3536C14.1583 14.5488 13.8417 14.5488 13.6464 14.3536L10.6464 11.3536C10.4512 11.1583 10.4512 10.8417 10.6464 10.6464ZM3 11L3.47105 11.242L4 11.2679L4.28693 11.713L4.73205 12L4.75799 12.5289L5 13L4.75799 13.471L4.73205 14L4.28693 14.2869L4 14.732L3.47105 14.758L3 15L2.52895 14.758L2 14.732L1.71307 14.2869L1.26795 14L1.24202 13.471L1 13L1.24202 12.5289L1.26795 12L1.71307 11.713L2 11.2679L2.52895 11.242L3 11Z"/>
  </svg>
</template>

<script>
export default {
  name: "AdminPanelIcon"
}
</script>
