const authorize = [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: true,
            auth: false,
        },
    },
    {
        path: '/registration',
        name: 'auth-register',
        component: () => import('@/views/Register.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: true,
            auth: false,
        },
    },
    {
        path: '/forgot-password',
        name: 'auth-forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: true,
            auth: false,
        },
    },
    {
        path: '/reset-password',
        name: 'auth-reset-password',
        component: () => import('@/views/ResetPassword.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: true,
            auth: false,
        },
    },
    {
        path: '/verify-email',
        name: 'auth-verify-email',
        component: () => import('@/views/VerifyEmail.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            redirectIfLoggedIn: false,
            auth: true,
            redirectIfVerified: true,
        },
    },
    {
        path: '/account-blocked',
        name: 'account-blocked',
        component: () => import('@/views/AccountBlocked.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
            auth: true,
            redirectIfNotBanned: true,
            redirectIfLoggedIn: false,
            allowBanned: true
        },
    },
]

export default authorize
