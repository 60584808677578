// initial state
import api from "@/common/services/api";
import store from "@/store";

const state = () => ({
    profileData: {},
    loading: false,
    loaded: false,
    sendRequestStatus: false,
    profileUpdating: false,
    profileUpdated: false,
    emptyDescriptionModal: false,
    sendConfirmed: false,
    profileRememberedLoading: false,
    profileRememberedLoaded: false,
    rememberedUsers: null,
    rememberedLoading: false,
    profileAdministrationData: false,
    searchAvailableAt: null,
    profileGuestModalOpened: false,
})

// getters
const getters = {
    profileData: state => state.profileData,
    profileLoading: state => state.loading,
    profileLoaded: state => state.loaded,
    sendRequestStatus: state => state.sendRequestStatus,
    profileUpdating: state => state.profileUpdating,
    profileUpdated: state => state.profileUpdated,
    emptyDescriptionModal: state => state.emptyDescriptionModal,
    sendConfirmed: state => state.sendConfirmed,
    profileRememberedLoading: state => state.profileRememberedLoading,
    profileRememberedLoaded: state => state.profileRememberedLoaded,
    rememberedUsers: state => state.rememberedUsers,
    rememberedLoading: state => state.rememberedLoading,
    profileAdministrationData: state => state.profileAdministrationData,
    searchAvailableAt: state => state.searchAvailableAt,
    profileGuestModalOpened: state => state.profileGuestModalOpened,
};

//actions
const actions = {
    sendEditRequest: ({commit, dispatch}, payload) => {
        commit('setProfileUpdating', true);
        commit('setProfileUpdated', false);
        api.setHeader();
        api.patch(`/profile/update`, payload)
            .then(
                success => {
                    dispatch('profile/fetchProfileData', {username: success.data.user.username, showLoader: false}, { root: true })
                    commit('account/setUserData', success.data.user, { root: true });
                    dispatch('alert/success', success, { root: true });
                    commit('setProfileUpdated', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setProfileUpdating', false);
        });
    },

    sendDescriptionEditRequest: ({commit, dispatch}, payload) => {
        commit('setProfileUpdating', true);
        commit('setProfileUpdated', false);
        api.setHeader();
        api.patch(`/profile/description-update`, payload)
            .then(
                success => {
                    dispatch('profile/fetchProfileData', {username: success.data.user.username, showLoader: false}, { root: true })
                    commit('account/setUserData', success.data.user, { root: true });
                    dispatch('alert/success', success, { root: true });
                    commit('setProfileUpdated', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setProfileUpdating', false);
        });
    },

    addToRememberedRequest: ({commit, dispatch}, payload) => {
        commit('setProfileRememberedLoaded', false);
        commit('setProfileRememberedLoading', true);
        api.setHeader();
        api.post(`/profile/remember-user`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                    commit('account/updateRememberedUsers', success.data.rememberedUsers, { root: true });
                    commit('account/updateRememberedNames', success.data.profileData.username, { root: true });
                    commit('setProfileRememberedLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setProfileRememberedLoading', false);
            commit('setProfileRememberedLoaded', false);
        });
    },

    removeRememberedUserRequest: ({commit, dispatch}, payload) => {
        api.setHeader();
        let data = {
            rememberedUsername: payload.rememberedUsername
        }
        api.post(`/profile/remove-remembered-user`, data)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                    commit('account/removeRememberedUser', payload.rememberedUsername, { root: true })
                    commit('account/removeRememberedNames', success.data.profileData.username, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchRememberedUsers: ({commit, dispatch}) => {
        api.setHeader();
        commit('setRememberedLoading', true);
        api.post(`/profile/get-remembered-users`)
            .then(
                success => {
                    commit('setRememberedProfiles', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setRememberedLoading', false);
        });
    },
}

//mutations
const mutations = {
    setProfileData (state, data) {
        state.profileData = data;
    },
    setLoading (state, value) {
        state.loading = value;
    },
    setLoaded (state, value) {
        state.loaded = value;
    },
    setUserFavouritedBy(state, value) {
        state.profileData.userFavouritedBy = value;
    },
    setSendRequestStatus(state, value) {
        state.sendRequestStatus = value;
    },
    setSendConfirmed(state, value) {
        state.sendConfirmed = value;
    },
    openEmptyDescriptionModal(state, value) {
        state.emptyDescriptionModal = value;
    },
    setProfileUpdating(state, value) {
        state.profileUpdating = value;
    },
    setProfileUpdated(state, value) {
        state.profileUpdated = value;
    },
    setProfileNewContact(state, value) {
        state.profileData.isUserNewContact = value;
    },
    setPicturesPending(state, value) {
        state.profileData.picturesPending += value;
    },
    setProfileRememberedLoaded(state, value) {
        state.profileRememberedLoaded = value;
    },
    setProfileRememberedLoading(state, value) {
        state.profileRememberedLoading = value;
    },
    setRememberedProfiles(state, data) {
        state.rememberedUsers = data;
    },
    setRememberedLoading(state, value) {
        state.rememberedLoading = value;
    },
    setUserRememberedByFalse(state, value) {
        state.profileData.userRememberedBy = false;
    },
    setProfileAdministrationData(state, data) {
        state.profileAdministrationData = data;
    },
    setSearchAvailableAt(state, value) {
        state.searchAvailableAt = value;
    },

    setProfileGuestModalOpened(state, value) {
        state.profileGuestModalOpened = value;
    }



}

export const profile = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
