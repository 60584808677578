// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";

const state = () => ({
    partnerCodesData: null,
    loadingPartnerCodesData: true,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    partnerCodesSaving: false,
    partnerCodeData: null,
    partnerCodeDeleting: null,
    partnerCodeLoaded: false,
    partnerCodeDeleted: false,

})

// getters
const getters = {
    partnerCodesData: state => state.partnerCodesData,
    partnerCodesDataLoading: state => state.loadingPartnerCodesData,
    pagination: state => state.pagination,
    partnerCodesSaving: state => state.partnerCodesSaving,
    partnerCodeData: state => state.partnerCodeData,
    partnerCodeDeleting: state => state.partnerCodeDeleting,
    partnerCodeLoaded: state => state.partnerCodeLoaded,
    partnerCodeDeleted: state => state.partnerCodeDeleted,

};

//actions
const actions = {
    fetchPartnerCodesRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setPartnerCodesDataLoading', true);
        api.query(`/admin/partner-codes`, payload, true)
        .then(
            success => {
                commit('setPartnerCodesData', success.data)
                commit('setPagination', success.meta)
            },
            error => {
                dispatch('alert/error', error, { root: true });
            }
        ).finally(() => {
            commit('setPartnerCodesDataLoading', false);
        });
    },

    storePartnerCodeData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPartnerCodesSaving', true);
        api.post(`/admin/partner-codes`, payload)
            .then(
                success => {
                    router.push({name: 'admin-partner-codes'});
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setPartnerCodesSaving', false);
        });
    },

    fetchShowPartnerCodeRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setPartnerCodeLoaded', false);
        api.get(`/admin/partner-codes`, payload, true)
            .then(
                success => {
                    commit('setPartnerCodeData', success.data)
                    commit('setPartnerCodeLoaded', true);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    updatePartnerCodeData: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPartnerCodesSaving', true);
        api.put(`/admin/partner-codes/${payload.id}`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPartnerCodesSaving', false);
        });
    },

    deletePartnerCodeRequest: ({commit, dispatch},  payload) => {
        api.setHeader();

        commit('setPartnerCodesDeleting', true);
        commit('setPartnerCodeDeleted', false);
        api.delete(`/admin/partner-codes/${payload.id}`, payload)
            .then(
                success => {
                    commit('setPartnerCodeDeleted', true);
                    commit('setPartnerCodesData', success.data.partnerCodes);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setPartnerCodesDeleting', false);
        });
    },


}

//mutations
const mutations = {
    setPartnerCodesData(state, data) {
        state.partnerCodesData = data;
    },

    setPartnerCodesDataLoading (state, value) {
        state.loadingPartnerCodesData = value;
    },

    setPartnerCodeData(state, data) {
        state.partnerCodeData = data;
    },

    setPagination (state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },

    setPartnerCodesSaving(state, value) {
        state.partnerCodesSaving = value;
    },

    setPartnerCodesDeleting(state, value) {
        state.partnerCodeDeleting = value;
    },

    setPartnerCodeLoaded(state, value) {
        state.partnerCodeLoaded = value;
    },

    setPartnerCodeDeleted(state, value) {
        state.partnerCodeDeleted = value;
    },


}

export const partnerCodes = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
