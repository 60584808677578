<template>
  <span class="absolute -top-1.5 dot flex-none" :class="[
      color,
      {
        '-right-2': settings && !relative,
        '-right-3': !settings && !relative,
        'relative': relative,
        'group-hover:hidden': hiddenOnHover
      }
  ]"></span>
</template>

<script>
export default {
  name: "Dot",
  props: {
    color: {
      default: 'green',
      type: String,
    },
    settings: {
      type: Boolean,
      default: false,
    },
    relative: Boolean,
    hiddenOnHover: Boolean,
  }
}
</script>

<style lang="scss">

.dot {
  height: 16px;
  width: 16px;
  background-color: theme('colors.badgeDefault');
  border-radius: 50%;
  border-width: 2px;
  border-color: #FFFFFF;
  display: inline-block;

  &.red {
    background: theme('colors.badgeError');
  }

  &.green {
    background: theme('colors.badgeSuccess');
  }

  &.orange {
    background: theme('colors.badgeInfo');
  }

  &.default {
    background-color: theme('colors.badgeDefault');
  }

  &.transparent {
    background: transparent;
  }
}
</style>