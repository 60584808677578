<template>
	<div>
		<img
      @load="onLoaded"
      @error="onError"
      :src="src"
      alt=""
      :class="[classes, attrs.class]"
      v-show="(loaded && !error)"
		/>
		<template v-if="loaderEnabled">
			<template v-if="isChat">
				<img
          v-if="!loaded"
          :class="[classes, 'rounded-full p-1', attrs.class]"
          alt=""
          :src="picturePlaceholder"
        />
			</template>
			<template v-else>
				<PictureLoader
          v-show="(!loaded || error)"
          :small="loaderSmall"
          :classes="loaderClasses"
				/>
			</template>
		</template>
	</div>
</template>

<script>
import Loader from "@/views/Loader";
import themeConfig from "@/themeConfig";
import PictureLoader from "@/views/PictureLoader.vue";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Picture",
  components: {PictureLoader, Loader},
  props: {
    src:[String, Function],
    alt: String,
    classes: String,
    isChat: Boolean,
	  loaderEnabled: {
			type: Boolean,
		  default: false,
	  },
	  loaderClasses: String,
    loaderSmall: Boolean,
	  picturesLength: Number,
  },
  data() {
    return {
	    loaded: false,
	    error: false,
      picturePlaceholder: themeConfig.staticPhotos.picturePlaceholder,
      pictureEmptyPlaceholder: themeConfig.staticPhotos.pictureEmptyPlaceholder,
    }
  },
	computed: {
		...mapGetters('utils', ['picturesLoadingCounter']),
		attrs() {
			const attrs = { ...this.$attrs }
			// adding class and style for `v-bind`
			attrs.class = this.$vnode.data.class

			return attrs
		}
	},
	methods: {
		...mapMutations('utils', ['setPicturesLoadingCounter']),
		onLoaded() {
			this.loaded = true;
			this.$emit('picture-loaded', true);
			if((this.picturesLoadingCounter !== null || this.picturesLoadingCounter === 0) && this.loaderEnabled) {
				let count = this.picturesLoadingCounter - 1;
				this.setPicturesLoadingCounter(count);
			}

		},
		onError() {
			this.error = true;
		}
	},
	beforeDestroy() {
		if(this.loaderEnabled) {
			this.setPicturesLoadingCounter(null)
		}
	},
	mounted() {
	}

}
</script>