<template>
  <svg width="16" height="16" viewBox="0 0 16 16"   >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.35355 1.64645C8.54882 1.84171 8.54882 2.15829 8.35355 2.35355L2.70711 8L8.35355 13.6464C8.54882 13.8417 8.54882 14.1583 8.35355 14.3536C8.15829 14.5488 7.84171 14.5488 7.64645 14.3536L1.64645 8.35355C1.45118 8.15829 1.45118 7.84171 1.64645 7.64645L7.64645 1.64645C7.84171 1.45118 8.15829 1.45118 8.35355 1.64645Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.3536 1.64645C12.5488 1.84171 12.5488 2.15829 12.3536 2.35355L6.70711 8L12.3536 13.6464C12.5488 13.8417 12.5488 14.1583 12.3536 14.3536C12.1583 14.5488 11.8417 14.5488 11.6464 14.3536L5.64645 8.35355C5.45118 8.15829 5.45118 7.84171 5.64645 7.64645L11.6464 1.64645C11.8417 1.45118 12.1583 1.45118 12.3536 1.64645Z"/>
  </svg>
</template>

<script>
export default {
  name: "ChevronLeftIcon",
}
</script>
