<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M 11.742 10.344 a 6.5 6.5 0 1 0 -1.397 1.398 h -0.001 q 0.044 0.06 0.098 0.115 l 3.85 3.85 a 1 1 0 0 0 1.415 -1.414 l -3.85 -3.85 a 1 1 0 0 0 -0.115 -0.1 z M 11.5 6.5 A 4 4 0 1 1 1.5 6.5 a 4 4 0 1 1 10 0"/>
  </svg>

</template>

<script>
export default {
  name: "SearchIcon",
}
</script>
