// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'

const state = () => ({
    loading: false,
    requestsPending: 0,
})

// getters
const getters = {
    loading: state => state.loading,
    requestsPending: state => state.requestsPending,
};

//actions
const actions = {
    show({ commit }) {
        commit("show");
    },
    hide({ commit }) {
        commit("hide");
    },
    pending({ commit }) {
        commit("pending");
    },
    done({ commit }) {
        commit("done");
    }
}

//mutations
const mutations = {
    show(state) {
        state.loading = true;
    },
    hide(state) {
        state.loading = false;
    },
    pending(state) {
        if (state.requestsPending === 0) {
            this.commit("loader/show");
        }
        this.commit("loader/requestsCounter")
    },
    done(state) {
        this.commit("loader/requestsCounter", true)

        if (state.requestsPending <= 0) {
            this.commit("loader/hide");
        }
    },

    requestsCounter(state, reverse = false) {
        if(reverse) {
            if (state.requestsPending >= 1) {
                state.requestsPending--;
                return;
            }
        }
        state.requestsPending++;
    }
}

export const loader = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
