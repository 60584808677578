import ApiService from "@/common/services/api";
import TokenService from "@/common/services/token";
import store from '@/store/index';

export const userAuthService = {
    login,
    logout,
    register,
    getUserData
};

function login(email, password, remember) {
    return ApiService.post('/login',{ email, password, remember });
}

function logout() {
    localStorage.removeItem('userData');
    localStorage.removeItem('accessToken')
    // Remove userData & Ability from localStorage
    localStorage.removeItem('userAbility')
    TokenService.destroyToken();
    window.Echo.leave(`messages.${store.getters['account/userData'].id}`);
    window.Echo.leave(`verification-status.${store.getters['account/userData'].username}`)
}

function register(user) {
    return ApiService.post('/register', user);
}

function getUserData() {
    return JSON.parse(localStorage.getItem('userData'));
}
