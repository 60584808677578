// initial state
import api from "@/common/services/api";


const state = () => ({
    verificationData: null,
    verificationDetails: null,
    currentVerification: null,
})

// getters
const getters = {
    verificationData: state => state.verificationData,
    verificationDetails: state => state.verificationDetails,
    currentVerification: state => state.currentVerification,
};

//actions
const actions = {
    fetchVerificationData: ({ commit, getters, dispatch}) => {
        api.get('/verifications', '', false)
            .then(
                success => {
                    commit('setVerificationData', success.data.verificationData)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchVerificationDetails: ({ commit, getters, dispatch}) => {
        api.get('/verifications/details', '', true)
            .then(
                success => {
                    commit('setVerificationDetails', success.data.verifications)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchCurrentVerificationData: ({ commit, getters, dispatch}) => {
        api.get('/verifications/current', '', true)
            .then(
                success => {
                    commit('setCurrentVerificationData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },
}

//mutations
const mutations = {
    setVerificationData (state, data) {
        state.verificationData = data
    },
    setVerificationDetails (state, data) {
        state.verificationDetails = data
    },
    setCurrentVerificationData (state, data) {
        state.currentVerification = data
    },

}

export const verification = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
