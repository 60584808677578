<template>
  <svg width="48" height="49" viewBox="0 0 48 49"  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.0607 11.1766C43.6464 11.7624 43.6464 12.7122 43.0607 13.298L25.0607 31.298C24.4749 31.8838 23.5251 31.8838 22.9393 31.298L4.93934 13.298C4.35355 12.7122 4.35355 11.7624 4.93934 11.1766C5.52513 10.5909 6.47487 10.5909 7.06066 11.1766L24 28.116L40.9393 11.1766C41.5251 10.5909 42.4749 10.5909 43.0607 11.1766Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43.0607 23.1766C43.6464 23.7624 43.6464 24.7122 43.0607 25.298L25.0607 43.298C24.4749 43.8838 23.5251 43.8838 22.9393 43.298L4.93934 25.298C4.35355 24.7122 4.35355 23.7624 4.93934 23.1766C5.52513 22.5909 6.47487 22.5909 7.06066 23.1766L24 40.116L40.9393 23.1766C41.5251 22.5909 42.4749 22.5909 43.0607 23.1766Z" />
  </svg>
</template>

<script>
export default {
  name: "ChevronDownBigIcon"
}
</script>
