
// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'
import router from "@/router";
import Vue from "vue";

const state = () => ({
    usersData: null,
    loadingUsersData:true,

    usersPaymentsData: null,
    loadingUsersPaymentsData: true,

    usersSaving: false,
    userDeleting: null,
    userLoaded: false,
    usersSelectData: null,
    userBanned: false,
    userBanning: false,
    userRemoved: false,
    userRemoving: false,

    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
})

// getters
const getters = {
    usersData: state => state.usersData,
    usersDataLoading: state => state.loadingUsersData,

    usersPaymentsData: state => state.usersPaymentsData,
    usersPaymentsDataLoading: state => state.loadingUsersPaymentsData,

    usersSaving: state => state.usersSaving,
    userDeleting: state => state.userDeleting,
    userLoaded: state => state.userLoaded,
    userDeleted: state => state.userDeleted,
    usersSelectData: state => state.usersSelectData,
    userBanned: state => state.userBanned,
    userBanning: state => state.userBanning,
    userRemoved: state => state.userRemoved,
    userRemoving: state => state.userRemoving,

    pagination: state => state.pagination,
};

//actions
const actions = {
    fetchUsersRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setUsersDataLoading', true);
        api.query(`/admin/users`, payload, true)
            .then(
                success => {
                    commit('setUsersData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setUsersDataLoading', false);
        });
    },

    fetchUsersPaymentsRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setUsersPaymentsDataLoading', true);
        api.query(`/admin/payments`, payload, true)
            .then(
                success => {
                    commit('setUsersPaymentsData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setUsersPaymentsDataLoading', false);
        });
    },

    banUserRequest: ({commit, dispatch}, payload) => {
        commit('setUserBanning', true);
        commit('setUserBanned', false);

        api.post(`/admin/users/ban`, payload)
            .then(
                success => {
                    commit('setUserBanned', true);
                    commit('updateUserData', success.data.user);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setUserBanning', false);
        });
    },

    removeUserRequest: ({commit, dispatch}, payload) => {
        commit('setUserRemoving', true);
        commit('setUserRemoved', false);

        api.post(`/admin/users/remove`, payload)
            .then(
                success => {
                    commit('setUserRemoved', true);
                    commit('updateUserData', success.data.user);
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setUserRemoving', false);
        });
    }

}

//mutations
const mutations = {
    setUsersData(state, data) {
        state.usersData = data;
    },
    setUsersDataLoading (state, value) {
        state.loadingUsersData = value;
    },

    setUsersSelectData(state, data) {
        state.usersSelectData = data;
    },

    setPagination (state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },

    setUsersSaving(state, value) {
        state.usersSaving = value;
    },

    setUsersDeleting(state, value) {
        state.userDeleting = value;
    },

    setUserLoaded(state, value) {
        state.userLoaded = value;
    },

    setUserDeleted(state, value) {
        state.userDeleted = value;
    },

    setUsersPaymentsData(state, data) {
        state.usersPaymentsData = data;
    },
    setUsersPaymentsDataLoading (state, value) {
        state.loadingUsersPaymentsData = value;
    },

    setUserBanned(state, value) {
        state.userBanned = value;
    },

    setUserBanning(state, value) {
        state.userBanning = value;
    },

    setUserRemoved(state, value) {
        state.userRemoved = value;
    },

    setUserRemoving(state, value) {
        state.userRemoving = value;
    },

    updateUserData(state, user) {
        let userIndex = state.usersData.findIndex(el => el.id === user.id);
        state.usersData[userIndex].bannedAt = user.bannedAt;
        state.usersData[userIndex].banReason = user.banReason;
        state.usersData[userIndex].deletedStatus = user.deletedStatus;
        state.usersData[userIndex].deletedAt = user.deletedAt;
        state.usersData[userIndex].removedAt = user.removedAt;
        state.usersData[userIndex].shouldColorRow = user.shouldColorRow;
        state.usersData[userIndex].name = user.name;
        state.usersData[userIndex].email = user.email;
    }


}

export const users = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
