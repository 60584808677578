<template>
  <svg width="16" height="16" viewBox="0 0 16 16">
    <path d="M15 8a6.973 6.973 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8ZM2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Z"/>
  </svg>
</template>

<script>
export default {
  name: "BanIcon"
}
</script>
