<template>
  <t-modal
      :variant="variant"
      ref="modal"
      v-model="showModalDialog"
  >
    <template v-slot:header>
      <slot name="header">
        {{ header }}
      </slot>
    </template>
	  <template v-slot:default>
		  <slot name="content" ref="modalContent">
		  </slot>
	  </template>
    <template v-slot:footer v-if="!hideFooter">
      <slot name="footer">

      </slot>
      <template v-if="!$slots.footer">
        <Button
          v-if=!cancelButtonDefault
          variant="buttonRedOutlined"
          :text="buttonCancelText ? buttonCancelText : trans('BUTTON:Cancel')"
          @clicked="cancelAction"
        />
        <Button
          :variant="cancelButtonDefault?'buttonRedOutlined':'buttonRed'"
          btn-type="submit"
          :loading="loading"
          :disabled="confirmDisabled"
          :text="buttonConfirmText ? buttonConfirmText : trans('BUTTON:Save')"
          @clicked="confirmAction"
        />
        <Button
          v-if=cancelButtonDefault
          variant="buttonRed"
          :text="buttonCancelText ? buttonCancelText : trans('BUTTON:Cancel')"
          @clicked="cancelAction"
        />
      </template>
    </template>
  </t-modal>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapGetters} from "vuex";


export default {
  name: "Modal",
  components: {Button},
  props: {
    loading: Boolean,
    disabled: Boolean,
    buttonConfirmText: String,
    showModal: Boolean,
    confirmDisabled: Boolean,
    showCancelButton: {
      default: true,
      type: Boolean,
    },
    variant: String,
    header: String,
    buttonCancelText: String,
    data: Object,
    hideFooter: Boolean,
    cancelButtonDefault: Boolean,
  },
  data() {
    return {
	    buttonPosition: { top: '0px', right: '0px' },
    }
  },
	watch: {
		showModal: {
			immediate: true,
			handler(val) {
				if (val) {
					this.updateButtonPosition();
					window.addEventListener('resize', this.updateButtonPosition);
					window.addEventListener('scroll', this.updateButtonPosition);
				} else {
					window.removeEventListener('resize', this.updateButtonPosition);
					window.removeEventListener('scroll', this.updateButtonPosition);
				}
			}
		}
	},
  computed: {
    showModalDialog: {
      get: function () {
        return this.showModal;
      },
      set: function (value) {
        if (value === false) {
          this.$emit('clicked');
        }
        return value;
      }
    }
  },
  methods: {
    confirmAction() {
      this.$emit('onConfirm');
    },
    cancelAction() {
      this.showModalDialog = false;
    },
	  updateButtonPosition() {
		  this.$nextTick(() => {
				setTimeout(() => {
					let buttons = document.getElementsByClassName("close-modal-button");
					let modalBodies = document.getElementsByClassName("modal-body");

					if (buttons.length === modalBodies.length) {
						for (let i = 0; i < buttons.length; i++) {
							const rect = modalBodies[i].getBoundingClientRect();

							const topPosition = rect.top;
							const rightPosition = window.innerWidth - rect.right;

							buttons[i].style.top = `${topPosition}px`;
							buttons[i].style.right = `${rightPosition}px`;
							buttons[i].style.position = 'fixed';

							this.buttonPosition = {
								top: `${topPosition}px`,
								right: `${rightPosition}px`
							};
						}
					}
				}, 10)

		  });
	  }
  },
	mounted() {
		if (this.showModal) {
			this.updateButtonPosition();
		}
		this.observer = new MutationObserver(() => {
			this.updateButtonPosition();
		});
		const modalBody = this.$refs.modal.$refs.body;
		if (modalBody) {
			this.observer.observe(modalBody, { attributes: true, childList: true, subtree: true });
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateButtonPosition);
		window.removeEventListener('scroll', this.updateButtonPosition);
	},
  setup() {
    return {

    }
  }
}
</script>

<style lang="scss">
</style>
