const Permissions = {
    ADMINISTRATORS_MANAGE: 'administrators manage',
    REPORTS_MANAGE: 'reports manage',

    PICTURES_MANAGE: 'pictures manage',
    DESCRIPTIONS_MANAGE: 'descriptions manage',
    USERS_MANAGE: 'users manage',
    PAYMENTS_MANAGE: 'payments manage',

    PARTNER_CODES_MANAGE: 'partner codes manage',
    PARTNERS_MANAGE: 'partners manage',
    TEXTS_MANAGE: 'texts manage',
    SUBSCRIPTIONS_MANAGE: 'subscriptions manage',

    PARAMETERS_MANAGE: 'parameters manage',
    TRANSLATIONS_MANAGE: 'translations manage',
}

export default Permissions;
