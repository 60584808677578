import api from "@/common/services/api";

const state = () => ({
    newsletterLoading: false,
})

// getters
const getters = {
    newsletterLoading: state => state.newsletterLoading,
};

//actions
const actions = {
    signToNewsletterRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        commit('setLoading', true);
        api.post(`/newsletter`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoading', false);
        });
    },

}

//mutations
const mutations = {
    setLoading (state, value) {
        state.newsletterLoading = value;
    },
}

export const newsletter = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
