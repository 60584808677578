var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"absolute -top-1.5 dot flex-none",class:[
    _vm.color,
    {
      '-right-2': _vm.settings && !_vm.relative,
      '-right-3': !_vm.settings && !_vm.relative,
      'relative': _vm.relative,
      'group-hover:hidden': _vm.hiddenOnHover
    }
]})}
var staticRenderFns = []

export { render, staticRenderFns }