<template>

  <svg width="25" height="25" viewBox="0 0 25 25"  >
    <path d="M12.7461 7.7373C13.9887 7.7373 14.9961 6.72995 14.9961 5.4873C14.9961 4.24466 13.9887 3.2373 12.7461 3.2373C11.5035 3.2373 10.4961 4.24466 10.4961 5.4873C10.4961 6.72995 11.5035 7.7373 12.7461 7.7373Z"/>
    <path d="M18.7461 12.2373C19.9887 12.2373 20.9961 11.2299 20.9961 9.9873C20.9961 8.74466 19.9887 7.7373 18.7461 7.7373C17.5035 7.7373 16.4961 8.74466 16.4961 9.9873C16.4961 11.2299 17.5035 12.2373 18.7461 12.2373Z"/>
    <path d="M8.99609 10.7373C8.99609 11.9799 7.98873 12.9873 6.74609 12.9873C5.50345 12.9873 4.49609 11.9799 4.49609 10.7373C4.49609 9.49466 5.50345 8.4873 6.74609 8.4873C7.98873 8.4873 8.99609 9.49466 8.99609 10.7373Z"/>
    <path d="M9.74609 19.7373C10.9887 19.7373 11.9961 18.7299 11.9961 17.4873C11.9961 16.2447 10.9887 15.2373 9.74609 15.2373C8.50345 15.2373 7.49609 16.2447 7.49609 17.4873C7.49609 18.7299 8.50345 19.7373 9.74609 19.7373Z"/>
    <path d="M24.7461 12.2373C24.7461 16.9632 21.9465 16.1148 19.396 15.3419C17.8755 14.8811 16.4435 14.4472 15.7461 15.2373C14.842 16.2617 15.0337 17.9653 15.2196 19.6163C15.4857 21.9807 15.7397 24.2373 12.7461 24.2373C6.11868 24.2373 0.746094 18.8647 0.746094 12.2373C0.746094 5.60989 6.11868 0.237305 12.7461 0.237305C19.3735 0.237305 24.7461 5.60989 24.7461 12.2373ZM12.7461 22.7373C13.6627 22.7373 13.7265 22.4802 13.7283 22.473C13.8454 22.2544 13.9144 21.7766 13.8332 20.7952C13.8123 20.5428 13.7784 20.2384 13.7417 19.9078C13.6643 19.2121 13.574 18.4006 13.5648 17.7147C13.5506 16.6555 13.69 15.3001 14.6215 14.2447C15.1739 13.6189 15.8886 13.3771 16.5286 13.317C17.1353 13.26 17.7477 13.3565 18.2684 13.4733C18.7831 13.5888 19.3219 13.7521 19.8062 13.8989L19.8492 13.9119C20.3673 14.0689 20.8348 14.2094 21.278 14.3099C22.2576 14.5321 22.6339 14.4346 22.765 14.3464C22.8222 14.308 23.2461 13.979 23.2461 12.2373C23.2461 6.43831 18.5451 1.7373 12.7461 1.7373C6.9471 1.7373 2.24609 6.43831 2.24609 12.2373C2.24609 18.0363 6.9471 22.7373 12.7461 22.7373Z"/>
  </svg>

</template>

<script>
export default {
  name: "PaletteIcon",

}
</script>
