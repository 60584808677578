const toQueryString = function(obj) {
    let parts = [];
    for (let i in obj) {
        if (obj.hasOwnProperty(i) && obj[i] !== null && obj[i] !== undefined) {
            parts.push(encodeURIComponent(i) + "=" + encodeURIComponent(obj[i]));
        }
    }
    return parts.join("&");
}

export default toQueryString;