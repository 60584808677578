<template>
  <svg width="13" height="16" viewBox="0 0 13 16"  >
    <path d="M10 1C8.89543 1 8 1.89543 8 3V7C9.10457 7 10 7.89543 10 9V14C10 15.1046 9.10457 16 8 16H2C0.895431 16 0 15.1046 0 14V9C0 7.89543 0.895431 7 2 7H7V3C7 1.34315 8.34315 0 10 0C11.6569 0 13 1.34315 13 3V7C13 7.27614 12.7761 7.5 12.5 7.5C12.2239 7.5 12 7.27614 12 7V3C12 1.89543 11.1046 1 10 1ZM2 8C1.44772 8 1 8.44772 1 9V14C1 14.5523 1.44772 15 2 15H8C8.55229 15 9 14.5523 9 14V9C9 8.44772 8.55229 8 8 8H2Z" fill="#212529"/>
  </svg>
</template>

<script>
export default {
  name: "LockOpenedIcon",
}
</script>

