const errors = [
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'content',
            resource: 'Public',
        },
    },
]

export default errors
