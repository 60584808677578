<template>
  <svg  width="16" height="16" viewBox="0 0 16 16">
    <path fill="#FF8000" d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM7 6.5C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zM4.285 9.567a.5.5 0 0 0-.183.683A4.498 4.498 0 0 0 8 12.5a4.5 4.5 0 0 0 3.898-2.25.5.5 0 1 0-.866-.5A3.498 3.498 0 0 1 8 11.5a3.498 3.498 0 0 1-3.032-1.75.5.5 0 0 0-.683-.183zm5.152-3.31a.5.5 0 0 0-.874.486c.33.595.958 1.007 1.687 1.007.73 0 1.356-.412 1.687-1.007a.5.5 0 0 0-.874-.486.934.934 0 0 1-.813.493.934.934 0 0 1-.813-.493z"/>
<!--  <svg width="17" height="17" viewBox="0 0 17 17"  >-->
<!--    <path d="M8.6745 15.2373C4.80851 15.2373 1.6745 12.1033 1.6745 8.2373C1.6745 4.37131 4.80851 1.2373 8.6745 1.2373C12.5405 1.2373 15.6745 4.37131 15.6745 8.2373C15.6745 12.1033 12.5405 15.2373 8.6745 15.2373ZM8.6745 16.2373C13.0928 16.2373 16.6745 12.6556 16.6745 8.2373C16.6745 3.81903 13.0928 0.237305 8.6745 0.237305C4.25622 0.237305 0.6745 3.81903 0.6745 8.2373C0.6745 12.6556 4.25622 16.2373 8.6745 16.2373Z" fill="#DD8000"/>-->
<!--    <path d="M4.95929 9.8045C5.19832 9.66623 5.50419 9.74791 5.64246 9.98694C6.24838 11.0344 7.37966 11.7373 8.67453 11.7373C9.96939 11.7373 11.1007 11.0344 11.7066 9.98694C11.8449 9.74791 12.1507 9.66623 12.3898 9.8045C12.6288 9.94277 12.7105 10.2486 12.5722 10.4877C11.7949 11.8314 10.3408 12.7373 8.67453 12.7373C7.00826 12.7373 5.55417 11.8314 4.77685 10.4877C4.63858 10.2486 4.72026 9.94277 4.95929 9.8045Z" fill="#DD8000"/>-->
<!--    <path d="M7.6745 6.7373C7.6745 7.56573 7.22678 8.2373 6.6745 8.2373C6.12221 8.2373 5.6745 7.56573 5.6745 6.7373C5.6745 5.90888 6.12221 5.2373 6.6745 5.2373C7.22678 5.2373 7.6745 5.90888 7.6745 6.7373Z" fill="#DD8000"/>-->
<!--    <path d="M9.43136 6.3004C9.67265 6.16612 9.97712 6.25287 10.1114 6.49417C10.2796 6.79648 10.5857 6.9873 10.9245 6.9873C11.2633 6.9873 11.5694 6.79648 11.7376 6.49417C11.8719 6.25287 12.1763 6.16612 12.4176 6.3004C12.6589 6.43469 12.7457 6.73915 12.6114 6.98044C12.2806 7.57484 11.654 7.9873 10.9245 7.9873C10.195 7.9873 9.56838 7.57484 9.2376 6.98044C9.10332 6.73915 9.19007 6.43469 9.43136 6.3004Z" fill="#DD8000"/>-->
  </svg>

</template>

<script>
export default {
  name: "EmojiWinkIcon"
}
</script>
