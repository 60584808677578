<template>
  <div class="bg-black p-3 lg:p-6 flex flex-col gap-3 lg:gap-6" v-if="render">
    <div class="flex flex-wrap gap-x-3 gap-y-1 justify-center lg:grid lg:grid-cols-5 lg:gap-3 lg:text-center">
      <div :class="{'flex flex-row justify-center items-center row-span-2':!isMobile}">
        <Button
          to="/"
          variant="linkFooterWhite"
          :text="trans('BUTTON:Homepage')"
        />
      </div>
      <div>
        <Button
            to="/about"
            variant="linkFooterWhite"
            :text="trans('BUTTON:About')"
        />
      </div>
      <div>
        <Button
          :to="{name: 'posts', params: {postCategory: 'news'}}"
          variant="linkFooterWhite"
          :text="trans('BUTTON:News')"
        />
      </div>
      <div>
        <Button
          :to="{name: 'posts', params: {postCategory: 'hints'}}"
          variant="linkFooterWhite"
          :text="trans('BUTTON:Hints')"
        />
      </div>
      <div>
        <Button
          :to="{name: 'posts', params: {postCategory: 'faq'}}"
          variant="linkFooterWhite"
          :text="trans('BUTTON:FAQ')"
        />
      </div>
      <div>
        <Button
          :to="{name: 'partners'}"
          variant="linkFooterWhite"
          :text="trans('BUTTON:Partners')"
        />
      </div>
      <div>
        <Button
          @clicked="goToPostCategory(getRulesPage)"
          variant="linkFooterWhite"
          :is-active="isRouteActive('rules')"
          :text="trans('BUTTON:Rules')"
        />
      </div>
      <div>
        <Button
          @clicked="goToPostCategory(getPrivacyPage)"
          variant="linkFooterWhite"
          :is-active="isRouteActive('privacy')"
          :text="trans('BUTTON:Privacy')"
        />
      </div>
      <div>
        <Button
          @clicked="goToPostCategory(getContactPage)"
          variant="linkFooterWhite"
          :is-active="isRouteActive('contact')"
          :text="trans('BUTTON:Contact')"
        />
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-center items-center gap-1.5">
      <div class="text-center text-gray-400 text-xs">
        {{ trans('LABEL:Project') }}: <a class="hover:opacity-50" target="_blank" href="https://inkographic.pl/">Inkographic Studio</a>
      </div>
      <div class="hidden lg:block text-center text-gray-400 text-xs">
        |
      </div>
      <div class="text-center text-gray-400 text-xs">
        <a class="hover:opacity-50" target="_blank" href="https://braincamp.pl/">BrainCamp.pl</a> © {{ year }}
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapGetters, mapMutations} from "vuex";
import router from "@/router";
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "Footer",
  components: {Button},
  props: {
    year: Number,
  },
  data() {
    return {
      render: false,
      isMobile: checkIsMobileDevice(),
    }
  },
  watch: {
    langLoaded: {
      immediate: true,
      handler(val) {
        if (val) {
          this.render = true;
        }
      }
    }
  },
  computed: {
    ...mapGetters('lang', ['langLoaded']),
    ...mapGetters('dictionaries', ['postCategoriesData']),
    getContactPage() {
      if(this.postCategoriesData) {
        return this.postCategoriesData.find(el => el.slug === 'contact');
      }
    },
    getPrivacyPage() {
      if(this.postCategoriesData) {
        return this.postCategoriesData.find(el => el.slug === 'privacy');
      }
    },
    getRulesPage() {
      if(this.postCategoriesData) {
          return this.postCategoriesData.find(el => el.slug === 'rules');
      }
    },
  },
  methods: {
		...mapMutations('posts', ['setCurrentOnlyPostSlug', 'setLastPage', 'setCurrentPage']),

    goToPostCategory(postCategory) {
	    this.setCurrentPage(1);
	    this.setLastPage(1);
      if(postCategory.hasOwnProperty('onlyPostSlug') && this.$route.name !== 'posts') {
        this.setCurrentOnlyPostSlug(postCategory.onlyPostSlug)
	      router.push(
          {
            name: 'posts',
            params: {
              postCategory: postCategory.slug,
              slug: postCategory.onlyPostSlug
            }
          }
        )
        return;
      }
      router.push(
        {
          name: 'posts',
          params: {postCategory: postCategory.slug}
        }
      )
    },

    isRouteActive(slug) {
      if(this.$route.params.postCategory) {
        return this.$route.params.postCategory.includes(slug);
      }
      return false;
    }
  }

}
</script>

