<template>

  <svg width="25" height="25" viewBox="0 0 25 25"  >
    <path d="M24.6924 1.26587C24.8039 0.987306 24.7386 0.669143 24.5264 0.456996C24.3143 0.24485 23.9961 0.179544 23.7175 0.290969L1.89689 9.01922L1.89471 9.0201L1.21756 9.29096C0.956299 9.39546 0.775515 9.63741 0.749353 9.91758C0.72319 10.1977 0.856052 10.469 1.09345 10.6201L1.70788 11.0111L1.71072 11.0129L9.20283 15.7806L13.9705 23.2727L13.9728 23.2762L14.3633 23.89C14.5144 24.1273 14.7857 24.2602 15.0658 24.234C15.346 24.2079 15.5879 24.0271 15.6924 23.7658L24.6924 1.26587ZM21.9427 4.10135L10.7018 15.3422L10.3788 14.8347C10.3199 14.742 10.2414 14.6635 10.1487 14.6046L9.64117 14.2816L20.882 3.04074L22.6498 2.33362L21.9427 4.10135Z"/>
  </svg>


</template>

<script>
export default {
  name: "SendMessageIcon",
}
</script>
