<template>
  <svg  width="16" height="16" viewBox="0 0 16 16">
    <path fill="#DC143C" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zM4.053 4.276a.5.5 0 0 1 .67-.223l2 1a.5.5 0 0 1 .166.76c.071.206.111.44.111.687C7 7.328 6.552 8 6 8s-1-.672-1-1.5c0-.408.109-.778.285-1.049l-1.009-.504a.5.5 0 0 1-.223-.67zm.232 8.157a.5.5 0 0 1-.183-.683A4.498 4.498 0 0 1 8 9.5a4.5 4.5 0 0 1 3.898 2.25.5.5 0 1 1-.866.5A3.498 3.498 0 0 0 8 10.5a3.498 3.498 0 0 0-3.032 1.75.5.5 0 0 1-.683.183zM10 8c-.552 0-1-.672-1-1.5 0-.247.04-.48.11-.686a.502.502 0 0 1 .166-.761l2-1a.5.5 0 1 1 .448.894l-1.009.504c.176.27.285.64.285 1.049 0 .828-.448 1.5-1 1.5z"/>
<!--  <svg  width="16" height="16"  viewBox="0 0 16 16">-->
<!--    <path d="M8.6745 15.2373C4.80851 15.2373 1.6745 12.1033 1.6745 8.2373C1.6745 4.37131 4.80851 1.2373 8.6745 1.2373C12.5405 1.2373 15.6745 4.37131 15.6745 8.2373C15.6745 12.1033 12.5405 15.2373 8.6745 15.2373ZM8.6745 16.2373C13.0928 16.2373 16.6745 12.6556 16.6745 8.2373C16.6745 3.81903 13.0928 0.237305 8.6745 0.237305C4.25622 0.237305 0.6745 3.81903 0.6745 8.2373C0.6745 12.6556 4.25622 16.2373 8.6745 16.2373Z" fill="#DC143C"/>-->
<!--    <path d="M4.95929 12.6701C5.19832 12.8084 5.50419 12.7267 5.64246 12.4877C6.24838 11.4402 7.37966 10.7373 8.67453 10.7373C9.96939 10.7373 11.1007 11.4402 11.7066 12.4877C11.8449 12.7267 12.1507 12.8084 12.3898 12.6701C12.6288 12.5318 12.7105 12.226 12.5722 11.9869C11.7949 10.6432 10.3408 9.7373 8.67453 9.7373C7.00826 9.7373 5.55417 10.6432 4.77685 11.9869C4.63858 12.226 4.72026 12.5318 4.95929 12.6701Z" fill="#DC143C"/>-->
<!--    <path d="M11.9509 4.29008C12.1979 4.16658 12.4982 4.2667 12.6217 4.51369C12.7452 4.76067 12.6451 5.06101 12.3981 5.18451L11.3896 5.68877C11.5658 5.9592 11.6745 6.32922 11.6745 6.7373C11.6745 7.56573 11.2268 8.2373 10.6745 8.2373C10.1222 8.2373 9.67447 7.56573 9.67447 6.7373C9.67447 6.49001 9.71437 6.25668 9.78504 6.05103C9.76304 6.02368 9.74361 5.99357 9.72727 5.9609C9.60378 5.71391 9.70389 5.41357 9.95088 5.29008L11.9509 4.29008Z" fill="#DC143C"/>-->
<!--    <path d="M5.39812 4.29008C5.15113 4.16658 4.85079 4.2667 4.7273 4.51369C4.6038 4.76067 4.70392 5.06101 4.9509 5.18451L5.95943 5.68877C5.78316 5.9592 5.67452 6.32922 5.67452 6.7373C5.67452 7.56573 6.12224 8.2373 6.67452 8.2373C7.22681 8.2373 7.67452 7.56573 7.67452 6.7373C7.67452 6.49001 7.63463 6.25668 7.56396 6.05103C7.58596 6.02368 7.60539 5.99357 7.62173 5.9609C7.74522 5.71391 7.64511 5.41357 7.39812 5.29008L5.39812 4.29008Z" fill="#DC143C"/>-->
  </svg>

</template>

<script>
export default {
  name: "EmojiAngryIcon"
}
</script>
