// initial state
import api from "@/common/services/api";
import store from "@/store";


const state = () => ({
    packagesData: null,
    subscriptionPending: null,
    subscriptionsData: null,
    pagination: {
        currentPage: null,
        from: null,
        lastPage: null,
        perPage: null,
        to: null,
        total: null,
    },
    lastPaymentPending: null,
    lastPaymentPendingDate: null,
    currentSubscription: null,
})

// getters
const getters = {
    packagesData: state => state.packagesData,
    subscriptionPending: state => state.subscriptionPending,
    subscriptionsData: state => state.subscriptionsData,
    lastPaymentPending: state => state.lastPaymentPending,
    lastPaymentPendingDate: state => state.lastPaymentPendingDate,
    currentSubscription: state => state.currentSubscription,
};

//actions
const actions = {
    fetchPackagesData: ({ commit, getters, dispatch}) => {
        api.setHeader();

        api.get('/packages', '', true)
            .then(
                success => {
                    commit('setPackagesData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },
    createPaymentRequest: ({commit, dispatch}, payload) => {
        api.setHeader();
        commit('setSubscriptionPending', payload.packageId);

        api.post('/payments/create', payload, false)
            .then(
                success => {
                    location.replace(success.data.redirectUri);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
            commit('setSubscriptionPending');
        });
    },

    fetchSubscriptionDataRequest: ({ commit, dispatch}) => {
        api.setHeader();

        api.get('/subscriptions', '', true)
            .then(
                success => {
                    commit('setSubscriptionsData', success.data)
                    commit('setPagination', success.meta)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchLastPaymentStatus: ({ commit, dispatch}) => {
        api.setHeader();

        api.get('/payments/last-payment-pending', '', true)
            .then(
                success => {
                    commit('setIsPaymentStatusPending', success.data.paymentPending)
                    commit('setPendingPaymentDate', success.data.paymentDate)
                    dispatch('fetchSubscriptionDataRequest');
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchSubscriptionStatusInfo: ({ commit, dispatch}) => {
        api.setHeader();

        api.get('/subscriptions/get-info', '', false)
            .then(
                success => {
                    commit('setSubscriptionUserDataInfo', success.data.subscription)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    fetchCurrentSubscriptionData: ({ commit, dispatch}) => {
        api.setHeader();

        api.get('/subscriptions/current', '', false)
            .then(
                success => {
                    commit('setCurrentSubscriptionData', success.data)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },
}

//mutations
const mutations = {
    setPackagesData (state, data) {
        state.packagesData = data
    },

    setSubscriptionsData (state, data) {
        state.subscriptionsData = data
    },

    setPagination(state, data) {
        state.pagination = {
            currentPage: data.current_page,
            from: data.from,
            lastPage: data.last_page,
            perPage: data.per_page,
            to: data.to,
            total: data.total,
        }
    },

    setSubscriptionPending (state, value) {
        if(value) {
            state.subscriptionPending = value;
        } else {
            state.subscriptionPending = null;
        }
    },

    setIsPaymentStatusPending (state, value) {
        state.lastPaymentPending = value;
    },

    setPendingPaymentDate (state, value) {
        state.lastPaymentPendingDate = value;
    },

    pushToSubscriptionsData (state, data) {
        state.subscriptionsData.unshift(data)
    },

    setSubscriptionUserDataInfo (state, data) {
        store.getters['account/userData'].subscription = data;
    },

    setCurrentSubscriptionData (state, data) {
        state.currentSubscription = data;
    }
}

export const subscriptions = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
