import partners from "@/router/app/admin/partners";
import posts from "@/router/app/admin/posts";
import partnerCodes from "@/router/app/admin/partnerCodes";
import packages from "@/router/app/admin/packages";
import users from "@/router/app/admin/users";

const admin = [
    {
        path: '/admin',
        component: () => import('@/views/content/admin/AdminView.vue'),
        children: [
            {
                path: '/',
                name: 'admin-dashboard',
                component: () => import('@/views/content/admin/dashboard/AdminDashboard.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                },
            },
            {
                path: '/admin/descriptions',
                name: 'admin-descriptions',
                component: () => import('@/views/content/admin/descriptions/AdminDescriptions.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                },
            },
            {
                path: '/admin/pictures',
                name: 'admin-pictures',
                component: () => import('@/views/content/admin/pictures/AdminPictures.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                },
            },
            {
                path: '/admin/configuration',
                name: 'admin-configuration',
                component: () => import('@/views/content/admin/configuration/AdminConfiguration.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                },
            },
            {
                path: '/admin/translations',
                name: 'admin-translations',
                component: () => import('@/views/content/admin/translations/AdminTranslations.vue'),
                meta: {
                    layout: 'content',
                    auth: false,
                    emailVerified: false,
                },
            },
            ...posts,
            ...partners,
            ...partnerCodes,
            ...packages,
            ...users
        ]
    },

]

export default admin
