<template>
  <li v-if="tooltipText && !checkIsMobileDevice()"
    class="flex flex-row items-center z-50"
    :class="{'justify-start': align!=='right','justify-end': align==='right'}"
  >
    <VTooltip class="flex flex-row items-center border-none">
      <slot>
        <!-- Content -->
      </slot>
      <template #popper>
        {{ trans(tooltipText) }}
      </template>
    </VTooltip>
  </li>
  <li v-else
    class="flex flex-row items-center"
    :class="{'justify-start': align!=='right','justify-end': align==='right'}"
  >
    <slot>
      <!-- Content -->
    </slot>
  </li>
</template>
<script>
import checkIsMobileDevice from "@/common/helpers/checkIsMobileDevice";

export default {
  name: "NavigationItem",
	methods: {checkIsMobileDevice},
  props: {
    tooltipText: String,
    align: String,

  }
}
</script>