<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M11 7.5C11 7.22386 11.2239 7 11.5 7H15.5C15.7761 7 16 7.22386 16 7.5C16 7.77614 15.7761 8 15.5 8H11.5C11.2239 8 11 7.77614 11 7.5Z"/>
    <path d="M1 14C1 14 0 14 0 13C0 12 1 9 6 9C11 9 12 12 12 13C12 14 11 14 11 14H1Z"/>
    <path d="M6 8C7.65685 8 9 6.65685 9 5C9 3.34315 7.65685 2 6 2C4.34315 2 3 3.34315 3 5C3 6.65685 4.34315 8 6 8Z"/>
  </svg>

</template>

<script>
export default {
  name: "RemoveUserFilledIcon"
}
</script>

