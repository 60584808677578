// initial state
import api from "@/common/services/api";
import toQueryString from "@/common/helpers/toQueryString";
import fromQueryToObject from "@/common/helpers/fromQueryToObject";
import store from '@/store/index'

const state = () => ({
    loadingUpdateProfile: false,
    profileUpdated: false,
    sendEditStatus: false,
})

// getters
const getters = {
    loadingUpdateProfile: state => state.loadingUpdateProfile,
    profileUpdated: state => state.profileUpdated,
    sendEditStatus: state => state.sendEditStatus
};

//actions
const actions = {
    updateProfileSettingsRequest: ({ commit, dispatch}, payload) => {
        commit('setLoadingUpdateProfile', true);
        commit('setProfileUpdated', false);

        api.setHeader();
        api.put(`/settings`, payload)
            .then(
                success => {
                    dispatch('account/updateUserData', success.data.user, {root: true})
                    dispatch('alert/success', success, { root: true });
                    commit('setProfileUpdated', true);

                    if(payload.hasOwnProperty('lang')) {
                        store.dispatch('lang/fetchLangData', success.data.user.lang);
                        store.commit('lang/setLocale', success.data.user.lang);
                    }
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
                commit('setLoadingUpdateProfile', false);
        });
    },
}

//mutations
const mutations = {
    setLoadingUpdateProfile (state, data) {
        state.loadingUpdateProfile = data;
    },
    setProfileUpdated (state, value) {
        state.profileUpdated = value;
    },
    setEditRequestStatus(state, value) {
        state.sendEditStatus = value;
    },
}

export const settings = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
