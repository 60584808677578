<template>

  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 0.5C13.7761 0.5 14 0.723858 14 1V5.8C14 7.18071 12.8807 8.3 11.5 8.3H1.70711L5.05355 11.6464C5.24882 11.8417 5.24882 12.1583 5.05355 12.3536C4.85829 12.5488 4.54171 12.5488 4.34645 12.3536L0.146447 8.15355C-0.0488155 7.95829 -0.0488155 7.64171 0.146447 7.44645L4.14645 3.44645C4.34171 3.25118 4.65829 3.25118 4.85355 3.44645C5.04882 3.64171 5.04882 3.95829 4.85355 4.15355L1.70711 7.3H11.5C12.3284 7.3 13 6.62843 13 5.8V1C13 0.723858 13.2239 0.5 13.5 0.5Z" />
  </svg>


</template>

<script>
export default {
  name: "BackIcon",

}
</script>
