<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M2.86457 14.5881C2.78677 15.0315 3.22433 15.3786 3.61077 15.18L8 12.9241L12.3892 15.18C12.7757 15.3786 13.2132 15.0315 13.1354 14.5881L12.3057 9.85857L15.828 6.50193C16.1572 6.18823 15.9868 5.61467 15.5458 5.55202L10.6474 4.85615L8.46323 0.52961C8.26648 0.13987 7.73352 0.13987 7.53677 0.52961L5.35265 4.85615L0.454172 5.55202C0.0131827 5.61467 -0.157217 6.18823 0.171963 6.50193L3.69429 9.85857L2.86457 14.5881Z" />
  </svg>
</template>

<script>
export default {
  name: "starFilledIcon"
}
</script>
