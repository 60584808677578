<template>
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M8 16.0164C12.1421 16.0164 15.5 12.6585 15.5 8.51641C15.5 5.05296 13.1523 2.13782 9.96155 1.27553C9.98658 1.188 9.99999 1.09557 9.99999 1C9.99999 0.447715 9.55227 0 8.99999 0H6.99999C6.4477 0 5.99999 0.447715 5.99999 1C5.99999 1.09557 6.0134 1.18801 6.03843 1.27553C2.84765 2.13783 0.5 5.05296 0.5 8.51641C0.5 12.6585 3.85786 16.0164 8 16.0164ZM14.5 8.51642C14.5 12.1063 11.5899 15.0164 8 15.0164C4.41015 15.0164 1.5 12.1063 1.5 8.51642C1.5 4.92657 4.41015 2.01642 8 2.01642C11.5899 2.01642 14.5 4.92657 14.5 8.51642Z" fill="#979797"/>
		<path d="M6.93939 7.43933L11.8891 4.6109L9.06071 9.56065L4.11096 12.3891L6.93939 7.43933Z" fill="#979797"/>
	</svg>
</template>

<script>
export default {
  name: "CompassIcon"
}
</script>
