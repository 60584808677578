<template>
  <LayoutContent v-if="langLoaded">
    <template>
        <router-view :key="$route.name"/>
    </template>
    <toast position="ne" :html="true"/>
  </LayoutContent>
</template>

<script>
import { Toast } from 'vuex-toast'
// Layouts
import LayoutContent from '@/layouts/LayoutContent';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import config from "@/config";
import Echo from 'laravel-echo';
import { localize } from 'vee-validate';
import Permissions from "@/common/enums/permissions";

export default {
  components: {
    LayoutContent,
    Toast
  },

  computed: {
    ...mapGetters('lang', ['langData', 'langLocale', 'locales', 'langLoaded']),
    ...mapGetters('account', ['accessToken', 'authDone', 'userData']),
    ...mapGetters('chat', ['echoActive']),

	  Permissions() {
		  return Permissions
	  },
  },
  watch: {
    langLocale(val) {
      localize(val);
      this.fetchOrientationData();
      this.fetchDescriptionStatuses();
      this.fetchRatesData();
      this.fetchExperiencesData();
      this.fetchSearchWhosData();
      this.fetchSearchWhatsData();
			this.fetchGenderDeclaredData();
			this.fetchGenders();
			this.fetchPostCategoriesData();

			if(this.userData) {
				this.fetchCurrentSubscriptionData();
				this.fetchStimulantsAndPetsData();
				this.fetchShapeData();
				this.fetchPreferenceData();
			}
    },
    authDone(val) {
      if(val && this.userData.hasOwnProperty('emailVerifiedAt') && this.userData.emailVerifiedAt !== null) {
	      if(this.accessToken !== null && !this.echoActive) {
		      window.Pusher = require('pusher-js/with-encryption');

		      window.Echo = new Echo({
			      broadcaster: 'pusher',
			      key: process.env.VUE_APP_PUSHER_KEY,
			      cluster: process.env.VUE_APP_PUSHER_CLUSTER,
			      authEndpoint: config.PUSHER_AUTH_ENDPOINT,
			      auth: {
				      headers: {
					      Authorization: `Bearer ${this.accessToken}`
				      }
			      },
			      encrypted: true
		      });
		      this.setEchoActive(true);
	      }

        this.getNotReadCount();
        this.fetchDashboardData();
        this.fetchShapeData();
        this.fetchPreferenceData();
        this.fetchOrientationData();
        this.fetchDescriptionStatuses();
        this.fetchRatesData();
        this.fetchExperiencesData();
        this.fetchSearchWhosData();
        this.fetchSearchWhatsData();
				this.fetchStimulantsAndPetsData();
	      if(this.hasPermission(this.userData, Permissions.ADMINISTRATORS_MANAGE)) {
		      this.fetchPermissions();
	      }
        window.Echo.encryptedPrivate(`verification-status.${this.userData.username}`).listen('VerificationStatus', (e) => {
          this.setVerificationData(e.verificationData);
          this.setUserVerification(e.verificationData);
          if(e.verificationData.verified) {
            this.setUserVerified(true);
          }
          if(this.$route.name === 'verification-details') {
            this.fetchVerificationDetails();
          }
        });

	      window.Echo.encryptedPrivate(`user-status.${this.userData.username}`).listen('UserBanned', (e) => {
		      this.updateUserBannedStatus(e);
					this.fetchDashboardData();
	      });
      }
    },
  },
  methods: {
    ...mapActions('lang', ['fetchLangData', 'updateLang']),
    ...mapActions('account', ['authRequest']),
    ...mapActions('dashboard', ['fetchDashboardData']),
    ...mapMutations('account', ['setUserData', 'setToken', 'setUserVerified', 'setUserVerification']),
    ...mapMutations('chat', ['setEchoActive']),
    ...mapActions('chat', ['getNotReadCount']),
    ...mapActions('alert', ['success']),
	  ...mapActions('subscriptions', ['fetchCurrentSubscriptionData']),
    ...mapActions('verification', ['fetchVerificationDetails']),
	  ...mapActions('permissions', ['fetchPermissions']),
    ...mapActions('dictionaries', [
        'fetchRatesData', 'fetchLocationsData', 'fetchAllLocationsData', 'fetchExperiencesData', 'fetchParentalData', 'fetchMaritalData',
        'fetchDescriptionStatuses', 'fetchShapeData', 'fetchOrientationData', 'fetchSearchWhatsData', 'fetchSearchWhosData',
        'fetchPreferenceData', 'fetchGenderDeclaredData', 'fetchGenders', 'fetchPostCategoriesData', 'fetchStimulantsAndPetsData'
    ]),
	  ...mapActions('subscriptions', ['fetchCurrentSubscriptionData']),
    ...mapMutations('verification', ['setVerificationData']),
    ...mapMutations('account', ['updateUserBannedStatus']),
    updateLanguageAction(lang) {
      this.fetchLangData(lang);
    },
    setViewHeight: function() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  mounted() {
    this.setViewHeight();
    this.fetchPostCategoriesData();
	  this.fetchGenderDeclaredData();
	  this.fetchGenders();

    window.addEventListener('resize', () => {
      this.setViewHeight();
    });
    if(this.$route.query.emailVerified) {
      this.success({message: 'Konto zostało aktywowane'})
    }
    localize(localStorage.getItem('langLocale'));
  },
  beforeMount() {
	  if(localStorage.getItem('userData')) {
		  this.setToken(localStorage.getItem('accessToken'));
		  this.authRequest();
	  } else {
		  this.fetchLangData();
	  }
  },
}
</script>
