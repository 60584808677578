const partnerCodes = [
    {
        path: '/admin/subscriptions',
        name: 'admin-packages',
        component: () => import('@/views/content/admin/subscriptions/AdminSubscriptionsList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/subscriptions/new',
        name: 'admin-package-new',
        component: () => import('@/views/content/admin/subscriptions/AdminSubscriptionShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/subscriptions/:id',
        name: 'admin-package-show',
        component: () => import('@/views/content/admin/subscriptions/AdminSubscriptionShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },

]

export default partnerCodes;
