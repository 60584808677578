<template>
  <svg width="1000" height="1000" viewBox="0 0 1000 1000"  >
    <path d="M962.8,159.7c-15.1,0-27.2,12.2-27.2,27.2v626.1c0,30.1-24.4,54.4-54.4,54.4h-27.2h-54.4H118.9c-30.1,0-54.4-24.4-54.4-54.4V186.9c0-30.1,24.4-54.4,54.4-54.4h245h81.7h326.7c30.1,0,54.4,24.4,54.4,54.4v326.7v81.7v163.3c0,15.1,12.2,27.2,27.2,27.2c15.1,0,27.2-12.2,27.2-27.2V602.8v-89.2V132.5c0-30.1-24.4-54.4-54.4-54.4H745H500H91.7c-45.1,0-81.7,36.6-81.7,81.7v680.6c0,45.1,36.6,81.7,81.7,81.7h816.7c45.1,0,81.7-36.6,81.7-81.7v-27.2V186.9C990,171.9,977.8,159.7,962.8,159.7z"/>
    <path d="M663.3,676.9H227.8c-15,0-27.2,12.2-27.2,27.2s12.2,27.2,27.2,27.2h435.6c15.1,0,27.2-12.2,27.2-27.2S678.4,676.9,663.3,676.9z"/>
    <path d="M663.3,540.8H227.8c-15,0-27.2,12.2-27.2,27.2c0,15.1,12.2,27.2,27.2,27.2h435.6c15.1,0,27.2-12.2,27.2-27.2C690.6,553,678.4,540.8,663.3,540.8z"/>
    <path d="M663.3,404.7H227.8c-15,0-27.2,12.2-27.2,27.2c0,15.1,12.2,27.2,27.2,27.2h435.6c15.1,0,27.2-12.2,27.2-27.2C690.6,416.9,678.4,404.7,663.3,404.7z"/>
    <path d="M554.4,323.1c15.1,0,27.2-12.2,27.2-27.2c0-15.1-12.2-27.2-27.2-27.2H336.7c-15,0-27.2,12.2-27.2,27.2c0,15.1,12.2,27.2,27.2,27.2H554.4z"/>
  </svg>
</template>

<script>
export default {
  name: "NewsIcon"
}
</script>
