const posts = [
    {
        path: '/admin/posts',
        name: 'admin-texts',
        component: () => import('@/views/content/admin/posts/AdminPostsList.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/posts/new',
        name: 'admin-texts-new',
        component: () => import('@/views/content/admin/posts/AdminPostShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
    {
        path: '/admin/posts/show/:id?',
        name: 'admin-texts-show',
        component: () => import('@/views/content/admin/posts/AdminPostShow.vue'),
        meta: {
            layout: 'content',
            auth: false,
            emailVerified: false,
        },
    },
]

export default posts;