<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.05001 2.7373C9.28164 1.59618 10.2905 0.737305 11.5 0.737305C12.7095 0.737305 13.7184 1.59618 13.95 2.7373H16V3.7373H13.95C13.7184 4.87843 12.7095 5.7373 11.5 5.7373C10.2905 5.7373 9.28164 4.87843 9.05001 3.7373H0V2.7373H9.05001ZM2.05001 7.7373C2.28164 6.59618 3.29052 5.7373 4.5 5.7373C5.70948 5.7373 6.71836 6.59618 6.94999 7.7373H16V8.7373H6.94999C6.71836 9.87842 5.70948 10.7373 4.5 10.7373C3.29052 10.7373 2.28164 9.87842 2.05001 8.7373H0V7.7373H2.05001ZM9.05001 12.7373C9.28164 11.5962 10.2905 10.7373 11.5 10.7373C12.7095 10.7373 13.7184 11.5962 13.95 12.7373H16V13.7373H13.95C13.7184 14.8784 12.7095 15.7373 11.5 15.7373C10.2905 15.7373 9.28164 14.8784 9.05001 13.7373H0V12.7373H9.05001Z" />
  </svg>
</template>

<script>
export default {
  name: "FiltersIcon"
}
</script>
