const subscription = [
    {
        path: '/subscription',
        name: 'subscription',
        component: () => import('../../views/content/subscriptions/SubscriptionView'),
        meta: {
            layout: 'content',
            emailVerified: true,
            allowBanned: false,
            auth: true,
        },
    },
    {
        path: '/subscription/details',
        name: 'subscription-details',
        component: () => import('../../views/content/subscriptions/SubscriptionDetails'),
        meta: {
            layout: 'content',
            emailVerified: true,
            auth: true,
            allowBanned: false,
        },
    },


]

export default subscription
