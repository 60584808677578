<template>
  <div class="absolute top-12 flex w-full items-center justify-center text-s">
    <div class="w-full bg-black text-white border-t lg:border-t-none border-b border-black items-center flex flex-row flex-wrap gap-x-2 lg:gap-x-6 px-3 lg:px-6 pt-1 pb-2 lg:pb-1 ">
      <Button
        :to="{name: 'admin-dashboard'}"
        variant="navLink"
        :text="trans('LABEL:AdminHomepage')"
      />
      |
      <div v-if="hasPermission(userData, Permissions.PICTURES_MANAGE)">
        <Button
          :to="{name: 'admin-pictures'}"
          :text="trans('LABEL:AdminPictures')"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminPictures') }}
      </div>
      <div v-if="hasPermission(userData, Permissions.DESCRIPTIONS_MANAGE)">
        <Button
          :to="{name: 'admin-descriptions'}"
          :text="trans('LABEL:AdminDescriptions')"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminDescriptions') }}
      </div>
      <div v-if="hasPermission(userData, Permissions.USERS_MANAGE)">
        <Button
          :to="{name: 'admin-users'}"
          :text="trans('LABEL:AdminUsers')"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminUsers') }}
      </div>
      <div v-if="hasPermission(userData, Permissions.PAYMENTS_MANAGE)">
        <Button
          :to="{name: 'admin-users-payments'}"
          :text="trans('LABEL:AdminUsersPaymentsList')"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminUsersPaymentsList') }}
      </div>
      |
      <div v-if="hasPermission(userData, Permissions.PARTNER_CODES_MANAGE)">
        <Button
          :to="{name: 'admin-partner-codes'}"
          :text="trans('LABEL:AdminPartnerCodes')"
          variant="navLink"
        />
        <Button
          :to="{name: 'admin-partner-codes-new'}"
          text=" [+]"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminPartnerCodes') + " [+]"}}
      </div>

      <div v-if="hasPermission(userData, Permissions.PARTNERS_MANAGE)">
        <Button
          :to="{name: 'admin-partners'}"
          :text="trans('LABEL:AdminPartners')"
          variant="navLink"
        />
        <Button
          :to="{name: 'admin-partners-new'}"
          text=" [+]"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminPartners') + " [+]" }}
      </div>

      <div v-if="hasPermission(userData, Permissions.TEXTS_MANAGE)">
        <Button
            :to="{name: 'admin-texts'}"
            :text="trans('LABEL:AdminTexts')"
            variant="navLink"
        />
        <Button
            :to="{name: 'admin-texts-new'}"
            text=" [+]"
            variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminTexts')+" [+]"}}
      </div>

      <div v-if="hasPermission(userData, Permissions.SUBSCRIPTIONS_MANAGE)">
        <Button
          :to="{name: 'admin-packages'}"
          :text="trans('LABEL:AdminSubscriptions')"
          variant="navLink"
        />
        <Button
          :to="{name: 'admin-package-new'}"
          text=" [+]"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminSubscriptions') + " [+]" }}
      </div>
	    |
      <div v-if="hasPermission(userData, Permissions.PARAMETERS_MANAGE)">
        <Button
          :to="{name: 'admin-configuration'}"
          :text="trans('LABEL:AdminParameters')"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminParameters')}}
      </div>
      <div v-if="hasPermission(userData, Permissions.TRANSLATIONS_MANAGE)">
        <Button
          :to="{name: 'admin-translations'}"
          :text="trans('LABEL:AdminTranslations')"
          variant="navLink"
        />
      </div>
      <div class="text-grayed" v-else>
        {{ trans('LABEL:AdminTranslations')}}
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/utils/crud/components/Button";
import {mapGetters} from "vuex";
import Permissions from "@/common/enums/permissions";

export default {
  name: "NavigationAdmin",
  components: {
    Button,
  },
	computed: {
		Permissions() {
			return Permissions
		},
		...mapGetters('account', ['userData']),
	}
}
</script>

<style lang="scss">
  .active-link {
    color: theme('colors.white') !important;
    opacity: .3 !important;
    &:hover {
      cursor: default;
    }
  }
</style>