<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
  </svg>

</template>

<script>
export default {
  name: "DotsHorizontalIcon",

}
</script>
