import api from "@/common/services/api";

const state = () => ({
    permissionsData: null,
})

// getters
const getters = {
    permissionsData: state => state.permissionsData,
};

//actions
const actions = {
    fetchPermissions: ({ commit, dispatch}) => {
        api.setHeader();
        api.get(`/admin/permissions`)
            .then(
                success => {
                    commit('setPermissionsData', success.data.permissions)
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },

    sendPermissionsRequest: ({ commit, dispatch}, payload) => {
        api.setHeader();
        api.post(`/admin/permissions`, payload)
            .then(
                success => {
                    dispatch('alert/success', success, { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                }
            ).finally(() => {
        });
    },
}

//mutations
const mutations = {
    setPermissionsData (state, data) {
        state.permissionsData = data;
    },
}

export const permissions = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

}
