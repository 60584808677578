<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M8 2.99998C10.7614 2.99998 13 5.23856 13 7.99998C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 7.99998C3 7.25455 3.1628 6.54851 3.45431 5.91432C3.56963 5.66341 3.45973 5.36652 3.20882 5.25119C2.95792 5.13586 2.66102 5.24577 2.54569 5.49667C2.19519 6.25921 2 7.10748 2 7.99998C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 7.99998C14 4.68627 11.3137 1.99998 8 1.99998V2.99998Z"/>
    <path d="M8 4.46622V0.533742C8 0.321783 7.75279 0.205993 7.58995 0.341687L5.23047 2.30793C5.11053 2.40788 5.11053 2.59209 5.23047 2.69204L7.58995 4.65828C7.75279 4.79397 8 4.67818 8 4.46622Z"/>
  </svg>

</template>

<script>
export default {
  name: "ResetIcon",

}
</script>
