const hostApi = "https://api.test.sapioseksualni.pl";
const portApi = 443;

const pusherAuthEndpoint = '/broadcasting/auth'

const baseHost = `${hostApi}${portApi ? `:${portApi}` : ``}`;
const basePath = `/api`;
const baseURLApi = baseHost + basePath;

const broadcastingAuthEndpoint = `${baseURLApi}${pusherAuthEndpoint}`;

export default {
    API_URL: baseURLApi,
    API_HOST: baseHost,
    API_PATH: basePath,
    PUSHER_AUTH_ENDPOINT: broadcastingAuthEndpoint,
}
