<template>
  <svg width="16" height="16" viewBox="0 0 16 16"  >
    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z"/>
  </svg>
</template>

<script>
export default {
  name: "startIcon"
}
</script>
